import React from "react";
import { Link } from "react-router-dom";

function CardName(props) {
  return (
    <>
      <div className="col-md-3 col-sm-4 col-6"  style={{minHeight:"500"}}>
        <div className="wrimagecard wrimagecard-topimage"   >
         {props.isNotUrl == true  ?  <div className="wrimagecard-topimage_title" onClick={props.onClick}>
          <center>
          <i  className={props.icon} style = {{"color":props.color??"#0f0","fontSize":props.fontSize??"30px"}}></i>
            {" "}
            <h6>{props.name}</h6>
          </center>
        </div>
        : <Link to={props?.url} >  
            
            <div className="wrimagecard-topimage_title">
              <center>
              <i  className={props.icon} style = {{"color":props.color??"#0f0","fontSize":props.fontSize??"30px"}}></i>
                {" "}
                <h6>{props.name}</h6>
              </center>
            </div>
          </Link>
         
         
  }
        </div>
      </div>
       
    </>
  );
}

export default CardName;
 