import React from "react";

function Footer() {
  return (
    <>
      <footer className="dash-footer">
        <div className="footer-wrapper">
          <div className="py-1">
            <span className="text-muted"> © 2022 PotiPay </span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
