import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../actions/auth";
import { Link } from "react-router-dom";
import { useLogEmail } from "../hooks/auth/LogEmail";
import { useUsername } from "../hooks/auth/useUsername";
import { useLogPassword } from "../hooks/auth/LogPassword";
import { useTypeAccount } from "../hooks/auth/useTypeAccount";
import { useLoding } from "../hooks/Loding";
import { useCompanyName } from "../hooks/useCompanyName";
import { useUrlWebsite } from "../hooks/useUrlWebsite"; 

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 5 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 5 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const { email, changeEmail } = useLogEmail();
  const { loading, setLoading } = useLoding();
  const { username, changeUsername } = useUsername();
  const { password, changePassword } = useLogPassword();
  const { companyNampe, changeCompanyName } = useCompanyName();
  const { urlWebsite, changeUrlWebsite } = useUrlWebsite();
  const [successful, setSuccessful] = useState(false);
  const { typeAccount, changeTypeAccount } = useTypeAccount();
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();
  useEffect(() => {
    const last_time =  localStorage.getItem('timer') ; 
      if(last_time < Date.now()){
        window.localStorage.clear(); //clear all localstorage 
      } 
    }, []);
  const handleRegister = (e) => {
    e.preventDefault(); 
  
    setSuccessful(false);
   form.current.validateAll() ;  
    if (checkBtn.current.context._errors.length === 0) {
      setLoading(true);
      dispatch(
        register(
          username,
          email,
          password,
          typeAccount,
          companyNampe,
          urlWebsite
        )
      )
        .then(() => {
          setSuccessful(true);
          setLoading(false);
          props.history.push("/home");
          // window.location.reload();
        })
        .catch(() => {
          setSuccessful(false);
          setLoading(false);
        });
    }
  };

  return (
    <div className="card">
      <div className="row align-items-center text-start">
        <div className="col-xl-6">
          <div className="card-body">
            <div className="">
              <h2 className="mb-3 f-w-600">Register</h2>
            </div>
            
            {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
              
            <Form onSubmit={handleRegister} ref={form}>
              {!successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="username"
                      value={username}
                      onChange={changeUsername}
                      validations={[required, vusername]}
                      autoComplete="name"
                      autoFocus
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <Input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={changeEmail}
                      validations={[required, validEmail]}
                      autoComplete="email" 
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={changePassword}
                      validations={[required, vpassword]}
                      required
                      autoComplete="new-password"
                    />
                  </div>
                 
                  {typeAccount === "Business" ? (
                    <>
                      <div className="form-group">
                        <label htmlFor="password" className="form-label">
                          copmany's name
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          name="companyNampe"
                          value={companyNampe}
                          onChange={changeCompanyName}
                          validations={[required]}
                          required
                          autoComplete="Copmany's Name"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="form-label">
                          Url Your Website
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          name="urlWebsite"
                          value={urlWebsite}
                          onChange={changeUrlWebsite}
                          validations={[required]}
                          required
                          autoComplete=" Url Your Website"
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )} 
                  <br />
                    <h6>Type of Account : </h6>
                   <div className="form-check px-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type_account"
                      checked={typeAccount === "Personal"}
                      value="Personal"
                      id="Personal"
                      onChange={changeTypeAccount}
                    />
                    <label className="form-check-label" htmlFor="Personal">
                      Personal
                    </label>
                  </div>
                  <div className="form-check  px-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type_account"
                      checked={typeAccount === "Business"}
                      id="Business"
                      value="Business"
                      onChange={changeTypeAccount}
                    />
                    <label className="form-check-label" htmlFor="Business">
                      Business
                    </label>
                  </div>
                  <br />
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value=""  id="agree"
                      required />
                    <label class="form-check-label" for="flexCheckDefault">
                    I agree with the{" "}
                      <a href="{{ route('termsAndConditions') }}">
                        terms and conditions
                      </a>
                      .
                    </label>
                  </div>

                  <br />

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block mt-2"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Sign Up</span>
                    </button>
                  </div>
                </div>
              )}
              <p className="my-4 text-center">
                Already&#039; have an account?
                <Link to={"/Login"} className="text-primary">
                  Login
                </Link>
              </p>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
        <div className="col-xl-6 img-card-side">
          <div className="auth-img-content">
            <img
              src="https://demo.rajodiya.com/erpgo-saas/assets/images/auth/img-auth-3.svg"
              alt=""
              className="img-fluid"
            />
            <h3 className="text-white mb-4 mt-5">
              “Attention is the new currency”
            </h3>
            <p className="text-white">
              The more effortless the writing looks, the more effort the writer
              actually put into the process.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
