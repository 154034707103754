import axios from "axios";

import authHeader from "./auth-header";
const API_URL = "https://dashboard.potipay.com/api/";

 




const payout = (paymentMethods, selectCard,amount,receiver,note) => {
 
  return axios
    .post(API_URL + "pay_personal", {
        payment_method : paymentMethods,
        card_id :selectCard,
        amount,
        receiver,
        note
        
    }, { headers: authHeader() });
};


const payoutHaram = (paymentMethods,  amount, receiver, note,phone,city) => {
  
  return axios
    .post(API_URL + "pay_personal", {
        payment_method : paymentMethods, 
        amount,
        receiver:phone,
        name:receiver,
        city,
        note
        
    }, { headers: authHeader() }).catch(function (error) { 
     })
    .then((response) => {
   
      return response;
    });
};
 

 
const payoutApi = (paymentMethods, selectCard,amount,receiver,note) => {
 
  const user = JSON.parse(localStorage.getItem("user"));

  return axios
    .post(API_URL + "pay_personal", {
        payment_method : paymentMethods,
        card_id :selectCard,
        amount,
        receiver,
        note,
        apikey:user.Accesskey
        
    }, { headers: authHeader() }) ;
};
 

export default {
  
    payout,
    payoutHaram,
    payoutApi
};
