import React from "react";

function Postback(props) {
  const responseCode = `<?php
$servername = "localhost";
$username = "username";
$password = "password";
$dbname = "myDB";

// white-list
$white_list = array("68.183.12.2"); 

// check ip
 if(in_array($_SERVER['REMOTE_ADDR'], $white_list)){
// get data 
$order_id = $_GET['order_id'];
$state = $_GET['state']; 
$url_image = $_GET['url_image']; 

// Create connection
$conn = new mysqli($servername, $username, $password, $dbname);
// Check connection
if ($conn->connect_error) {
  die("Connection failed: " . $conn->connect_error);
}

$sql = "UPDATE payment SET state_order ='$state' WHERE order_id=$order_id";

if ($conn->query($sql) === TRUE) {
  echo 1;
} else {
  echo 0;
}

$conn->close();
}
?>
 
`;

  return (
    <>
      <div className="card-body pt-5">
        <h5 className="card-title">Postback</h5>
        <span>
        When the transfer request is executed by the administration,
         we will send you a URL request, called a 'Server to Server Postback' with some information
        . Using this information, you can modify the status of your order and take any of them
            Appropriate action <br />
          The postback will be sent to the global postback URL you have defined
          in your my App Placement section.
          <br />
          <br />
          The information will be sent to your postback URL with parameters .
          <br />
          The link will be similar to this: <br />
          <pre className="language-html">
            <code>
              <div>
                {" "}
                https://www.Your-Site.com.com/Your-postback.php?order_id=10&state=1
              </div>
            </code>
          </pre>
<br />
          if method pay as (haram) or any company need Receiving report or Send notice the Link will be like  this: <br />
          <br />
          <pre className="language-html">
            <code>
              <div>
                {" "}
                https://www.Your-Site.com.com/Your-postback.php?order_id=10&state=1&url_image=htttps://dashboard.potipay.com/uploads/haram/img_123456.png
              </div>
            </code>
          </pre>
          <br /> - :order_id : This is the order number that you received after
          sending the transfer request (refer to the previous step to see how
          the response is after the transfer request, you will find: order_id)
          <br /> - state : The status of the request is either (1) or (0)
          <br /> If (1) this means that the request has been fulfilled
          <br /> or (0) means that the request was rejected or field
          <br /> - url_image : link image  Receiving report or Send notice 
        </span>
        <h6> Postback IPs </h6>
        <p>
          All of our postbacks shall be sent from the following PotiPay IP:
          68.183.12.2 Please white-list this IP as this is the ONLY IP we'll be
          sending postbacks from.
        </p>

        <h6> simple example for postbak file : </h6>
        <pre className="language-html">
          <code>
            <div>{responseCode}</div>
          </code>
        </pre>
      </div>
    </>
  );
}

export default Postback;
