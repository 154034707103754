import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://dashboard.potipay.com/api/";
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};


const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getPaymentMethods = () => {
  return axios.get(API_URL + "payment_methods", { headers: authHeader() });
};

const getHistoryPay = () => {
  return axios.get(API_URL + "history_call_personal", { headers: authHeader() });
};

const getHistoryPayApi = () => {
  return axios.get(API_URL + "history_call_api", { headers: authHeader() });
};

const getStatistics = (page,startDate=null, endDate=null) => {
  return axios.get(API_URL + "statistics", { headers: authHeader(), params: {
    page,
    "start_date": startDate,
    "end_date":endDate
  } });
};

const getStatisticsUser = (page) => {
  return axios.get(API_URL +"statistics_personal" ,  { headers: authHeader() ,params: {
    page
  } });
};

const getStatisticsUserDate = (startDate, endDate) => {
  return axios.get(API_URL +"statistics_personal" ,  { headers: authHeader() ,params: {
    startDate, endDate
  } });
};

// const   = () => { 
//   return axios.post(API_URL + "key_api", { headers: authHeader() })
//    .then((response) => { 
//     if (response.data.success) {
//       localStorage.setItem("Accesskey",  response.data);  
//     }

//   return response.data;
//   }).catch(error => { 
//     console.error('Error during service worker registration:', error);
//   });
// };

 

 
// const createApiKey = () => { 
 
//   return axios.post(API_URL + "key_api", { headers: authHeader() }).then((response) => {
    
//     return response.data;
//     }).catch(error => {
//       console.error('Error during service worker registration:', error);
//     }); 
// };





const updatePassword = (oldPassword , newPassword,c_password) => { 
  return axios.post(API_URL + "updateUsername",{oldPassword , newPassword,"confirm-password":c_password}, { headers: authHeader() })
   .then((response) => {
    
  return response.data;
  }).catch(error => {
    console.error('Error during service worker registration:', error);
  });
};

const updateUser = (username,email,image,phone,urlPostback) => { 
  return axios.post(API_URL + "update",{name:username,email,image,phone,url_postback:urlPostback}, { headers: authHeader() })
   .then((response) => {
   
  return response.data;
  }).catch(error => {
    console.error('Error during service worker registration:', error);
  });
};


const sendCommentToApi = (ticket_id,comment,image) => { 
  const user = JSON.parse(localStorage.getItem("user"));
  const config = {
    headers: {
      'content-type': 'multipart/form-data', 
       Authorization: "Bearer " + user.data.token,
      Accept: 'application/json',
    },
  };
  const formData = new FormData();
  formData.append('ticket_id', ticket_id);
  formData.append('comment', comment);
  formData.append('image',  image );
  
  return axios.post(API_URL + "comment",formData, config)
   .then((response) => {
    
  return response.data;
  }).catch(error => {
    console.error('Error during service worker registration:', error);
  });
};


const sendBalanceToApi = (receiver,amount,note) => { 
  return axios.post(API_URL + "sendBalance",{receiver,amount,note}, { headers: authHeader() })
   .then((response) => {
    
  return response.data;
  }).catch(error => {
    console.error('Error during service worker registration:', error);
  });
};

const myTickets = () => { 
  return axios.get(API_URL + "my_tickets", { headers: authHeader() });
};
 
const getTicket = (id) => { 
  return axios.get(API_URL + "tickets/"+id, { headers: authHeader() });
};



const getHistorySendBalance = (id) => { 
  return axios.get(API_URL + "history_send_balance", { headers: authHeader() });
};


const getHistoryReciveBalance = (id) => { 
  return axios.get(API_URL + "history_receive_balance", { headers: authHeader() });
};



const openTicketApi = (title,message) => { 
  return axios.post(API_URL + "new_ticket",{title,message}, { headers: authHeader() })
   .then((response) => { 
  return response.data;
  }).catch(error => {
    console.error('Error during service worker registration:', error);
  });
};

const getCardsCompany = (id) => {
  return axios.get(API_URL + "cards_company/"+id, { headers: authHeader() });
};

const make_notifition_read = () => {
  return axios.get(API_URL + "make_notifition_read", { headers: authHeader() });
};

const checkCode = (code) => {
  return axios.get(API_URL + "check_code_email/"+code, { headers: authHeader() });
};

const resendNewCode = () => {
  return axios.get(API_URL + "resend_code" , { headers: authHeader() });
};

const getCompanyDeposit = () => {
  return axios.get(API_URL + "deposit_methods" , { headers: authHeader() });
};

const addDeposit = (companyId, amount, numberBill) => { 
  return axios.post(API_URL + "addDeposit",{id:companyId,amount,number_bill:numberBill}, { headers: authHeader() })
   ;
};

const getDepositHistory = (page) => {
  return axios.get(API_URL +"deposit_history" ,  { headers: authHeader() ,params: {
    page
  } });
};


const getPurchases = (page) => {
  return axios.get(API_URL +"getMyRealCards" ,  { headers: authHeader() ,params: {
    page
  } });
};

const getOneRequestByID = (id) => {
  return axios.get(API_URL +"getOneRequestByID/"+id ,  { headers: authHeader() });
};

const getMyApps = () => {
  return axios.get(API_URL +"get_my_apps" ,  { headers: authHeader() });
};


 
const getDataApp = (id) => {
  return axios.get(API_URL +"get_data_app/"+id ,  { headers: authHeader() });
};


const updateKeyApp = (id) => { 
  return axios.post(API_URL + "update_key_app/"+id,null, { headers: authHeader() }) ;
};

const updatePostback = (id,url) => { 
  return axios.post(API_URL + "update_postback/"+id,{url}, { headers: authHeader() }) ;
};

const createNewApp = (app_name,url_website) => { 
  return axios.post(API_URL + "addAppUser",{app_name,url_website}, { headers: authHeader() }) ;
};
const testPostbact = (id,order_id,state) => { 
  return axios.post(API_URL + "testPostbact",{id,order_id,state}, { headers: authHeader() }) ;
};
const updateWhitelist1 = (id,data) => { 
  return axios.post(API_URL + "whitelist/"+id,data, { headers: authHeader() }) ;
};

export default {
  getPublicContent,
  getUserBoard,
  getHistoryPay,
  getStatistics,
  getPaymentMethods ,
  getHistoryPayApi,
   
  updateUser,
  updatePassword, 
  getStatisticsUser,
  myTickets,
  getTicket,
  sendCommentToApi,
  sendBalanceToApi,
  getHistorySendBalance,
  getHistoryReciveBalance,
  openTicketApi,
  getCardsCompany,
  make_notifition_read,
  checkCode,
  resendNewCode, 
  getStatisticsUserDate,
  getCompanyDeposit,
  addDeposit,
  getDepositHistory,
  getPurchases,
  getOneRequestByID,
  getMyApps,
  getDataApp,
  updateKeyApp,
  updatePostback,
  createNewApp,
  testPostbact,
  updateWhitelist1,
};