import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBoard } from "../actions/userAction";
import BarHistory from "../components/BarHistory";
import { logout } from "../actions/auth";
import { Redirect } from "react-router-dom";
import { checkSession } from "../functions/forms";

const PendingAccount = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getDataUser();
  }, []);
  const getDataUser = () => {
    dispatch(getUserBoard())
      .then((response) => {
        if (response.data.data.user.state_company === "active") {
          window.location.href = "/home";
        }
      })
      .catch((e) => {});
  };
  const logOut = useCallback(() => {
    dispatch(logout());
    window.location.href = "/login";
  }, [dispatch]);

  return (
    <>
      <div className="dash-container">
        <div className="dash-content">
          <BarHistory namePage="Pending" />
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                حسابك قيد المراجعة سوف يتم ارسال رسالة الى بريدك الالكتروني عند
                الموافقة على اضافة حسابك
                <a className="dropdown-item" onClick={logOut}>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingAccount;
