import React, { useState } from "react";
import { checkSession } from "../../functions/forms";
import UserService from "../../services/user.service";
import toast, { Toaster } from "react-hot-toast";
import { useLoding } from "../../hooks/Loding";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import TableHistoryApp from "../../components/TableHistoryApp";
import TableHistoryPostback from "../../components/TableHistoryPostback";

const ShowApp = () => {
  const { loading, setLoading } = useLoding();

  const [urlPostback, setUrlPostback] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [history, setHistory] = useState([]);
  const [orderId, setOrderId] = useState();
  const [postback, setPostback] = useState([]);
  const [stateOrder, setStateOrder] = useState();
  const [editKey, setEditKey] = useState(false);
  const [whitelist, setWhitelist] = useState('');
  
  const [content, setContent] = useState([]);
  let { id } = useParams();
 
  useEffect(() => {
    getDataAPi(id);
  }, []);

  const getDataAPi = (id) => {
    UserService.getDataApp(id).then(
      (response) => {
        console.log();
        setHistory((state) => {
          return response.data.data.history;
        });
        setContent((state) => {
          return response.data.data.app;
        });
        setPostback((state) => {
          return response.data.data.postback;
        });
        
        setWhitelist((state) => {
          return response.data.data.app.whitelist;
        });
        setUrlPostback(response.data.data.app.url_postback);
        setAccessKey(response.data.data.app.access_key);
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setLoading(false);
        setContent(_content);
      }
    );
  };

  const copy = () => {
    checkSession();
    navigator.clipboard.writeText(accessKey);

    toast.success("Copied!");
  };

  
  function updatePostback(e) {
    e.preventDefault();
    setLoading(true);
    UserService.updatePostback(id, urlPostback)
      .then((response) => {
        if (response.data.success) {
          setUrlPostback(response.data.data);
        }
        setLoading(false);
      })
      .catch(function (error) { 
        setLoading(false);
      });
    setEditKey(false);
  }
  function updateAccessKey() {
    if (content.state_app === "active") {
      setLoading(true);
      UserService.updateKeyApp(id)
        .then((response) => {
          if (response.data.success) {
            setAccessKey(response.data.data);
          }
          setLoading(false); 
        })
        .catch(function (error) { 
          setLoading(false);
        });
    } else {
      toast.error("sorry , Inactive application!");
    }
  }
  //
  const changeUrlPostback = (event) => {
    const url = event.target.value;
    setUrlPostback(url);
  };

  const changeOrderId = (event) => {
    const id = event.target.value;
    setOrderId(id);
  };

  const changeStateOrder = (event) => {
    const state = event.target.value;
    setStateOrder(state);
  };
  

  function updateWhitelist(e) {
    e.preventDefault();
    setLoading(true);
    const data = { 
      whitelist:whitelist,
    };

    UserService.updateWhitelist1(id,data)
      .then((response) => { 
        if (response.data.success) {
          toast.success(
            "response from your website : " + response.data.response
          );
          toast.success(response.data.data);
        } else {
          toast.error("response from your website : " + response.data.response);
          toast.error(response.data.data);
        }

        setLoading(false);
      })
      .catch(function (error) { 
        toast.error("sorry , Inactive application!");
        setLoading(false);
      });
  }


  function testPostbact(e) {
    e.preventDefault();
    setLoading(true);
    UserService.testPostbact(id, orderId, stateOrder)
      .then((response) => { 
        if (response.data.success) {
          toast.success(
            "response from your website : " + response.data.response
          );
          toast.success(response.data.data);
        } else {
          toast.error("response from your website : " + response.data.response);
          toast.error(response.data.data);
        }

        setLoading(false);
      })
      .catch(function (error) { 
        toast.error("sorry , Inactive application!");
        setLoading(false);
      });
  }

  return (
    <div className="dash-content pt-3 mt-3 border-top">
      <div className="row pb-30 ">
        <div className="col-9">
          <h2>{content.app_name}</h2>
        </div>

        <div className="col-2 stateApp">
          <h5>
            {" "}
            {content.state_app === "active" ? (
              <span className="badge rounded-pill bg-primary p-2">
                {content.state_app}
              </span>
            ) : (
              <span className="badge rounded-pill bg-danger  p-2">
                {content.state_app}
              </span>
            )}
          </h5>
        </div>
      </div>
      <div className="row px-3 pb-3">
        <div className="col-sm-3 urlApp ">
          <h5>{content.url_website}</h5>
        </div>

        <div className="row mt-2">
          {editKey === true ? (
            <>
              <div className="row">
                <div className="col-10">
                  <form onSubmit={updatePostback}>
                    <div className="mb-3">
                      <label htmlFor="urlPostback" className="form-label">
                        {" "}
                        url postback:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="urlPostback"
                        value={urlPostback}
                        onChange={changeUrlPostback}
                      />
                    </div>

                    <input
                      type="submit"
                      value="update"
                      className="btn btn-primary"
                      disabled={loading}
                    />
                  </form>
                </div>{" "}
                <div className="col-2">
                  {" "}
                  <button
                    className="btn btn-danger float-end "
                    onClick={() => setEditKey(false)}
                  >
                    <i className="ti ti-letter-x"></i>{" "}
                  </button>{" "}
                </div>{" "}
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-10">Url Postback: {urlPostback}</div>{" "}
                <div className="col-2">
                  <button
                    className="btn btn-primary float-end"
                    onClick={() => setEditKey(true)}
                  >
                    <i className="ti ti-edit"></i>{" "}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Api Key
          </button>
          <button
            className="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            orders history{" "}
          </button>
          <button
            className="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            postback history
          </button>
          <button
            className="nav-link"
            id="nav-tester-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-tester"
            type="button"
            role="tab"
            aria-controls="nav-tester"
            aria-selected="false"
          >
            tester postback
          </button>
          <button
            className="nav-link"
            id="nav-tester-tab"
            data-bs-toggle="tab"
            data-bs-target="#whitelist"
            type="button"
            role="tab"
            aria-controls="nav-tester"
            aria-selected="false"
          >
           whitelist IPs
          </button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <br />
          <blockquote className="blockquote  my-3">
            <div className="alert alert-primary  mt-2" role="alert">
              {accessKey}
              <button
                onClick={copy}
                className="btn btn-outline-primary float-right"
              >
                <i className="ti ti-copy"></i>
              </button>
            </div>
            <div className="span2 mt-2"></div>

            <footer className="blockquote-footer pt-3">
              Be careful , If you want create new api key , the previous one
              will stop
            </footer>
          </blockquote>

          <input
            type="submit"
            value="update key"
            className="btn btn-warning"
            onClick={updateAccessKey}
            disabled={loading}
          />
          <br />
        </div>
        <div
          className="tab-pane fade  my-3"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <TableHistoryApp arr={history} isLoading={loading} />
        </div>
        <div
          className="tab-pane fade  my-3"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <TableHistoryPostback arr={postback} isLoading={loading} />
        </div>

        <div
          className="tab-pane fade"
          id="nav-tester"
          role="tabpanel"
          aria-labelledby="nav-tester-tab"
        >
          <form onSubmit={testPostbact}>
            <div className="my-3">
              <label htmlFor="orderId" className="form-label">
                Order Id
              </label>
              <input
                type="text"
                className="form-control"
                id="orderId"
                value={orderId}
                onChange={changeOrderId}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="stateOrder" className="form-label">
                State order
              </label>
              <select
                className="form-control"
                id="stateOrder"
                onChange={changeStateOrder}
              >
                <option value="0">0 - false </option>
                <option value="1">1 - true </option>
              </select>
            </div>

            <input
              type="submit"
              className="btn btn-primary"
              value="test"
              disabled={loading}
            />
          </form>
          <hr />
        </div>

        <div
          className="tab-pane fade"
          id="whitelist"
          role="tabpanel"
          aria-labelledby="nav-tester-tab"
        >   
        <form onSubmit={updateWhitelist}>
        <div className="my-3">
              <label htmlFor="orderId" className="form-label">
              Whitelist IPs
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="127.0.0.1;1.1.1.1;12......"
                id="orderId"
                value={whitelist}
                onChange={(e) => setWhitelist(e.target.value)}
              />
            </div>
        <input
              type="submit"
              className="btn btn-primary"
              value="save"
              disabled={loading}
            />

          </form>
        </div>

      </div>

      <Toaster />
    </div>
  );
};

export default ShowApp;
