import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import { sendCommentToApi } from "../actions/userAction";
import UserService from "../services/user.service";
import BarHistory from "../components/BarHistory";
import Comment from "../components/Comment";
import { checkSession } from "../functions/forms";

const Ticket = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);
  const [comment, setComment] = useState("");
  const [image, setImage] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  let { id } = useParams();

  let image_user = "default.png";
  const dispatch = useDispatch();

  useEffect(() => {
    checkSession();
    UserService.getTicket(id).then(
      (response) => {
        setContent(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);
  if (!currentUser) {
    return <Redirect to="/login" />;
  } else {
    image_user = currentUser?.data?.user?.image
      ? currentUser?.data?.user?.image
      : image_user;
  }

  const sendComment = (e) => {
    e.preventDefault();
    checkSession();
    setLoading(true);
    if (comment) {
      dispatch(sendCommentToApi(id, comment, image))
        .then((response) => {
          setContent(response.data);
          setLoading(false);
          // props.history.push("/profile");
          // window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  function handleChange(event) {
    checkSession();
    setImage(event.target.files[0]);
  }

  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="Ticket " />
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <h6 className="mb-0">{content.title}</h6>
                  </div>
                  <div className="card-body py-0">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item px-0">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <span className="form-label">Ticket Code:</span>
                          </div>
                          <div className="col-6 text-end">
                            #{content.ticket_id}
                          </div>
                        </div>
                      </li>

                      <li className="list-group-item px-0">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <span className="form-label">category:</span>
                          </div>
                          <div className="col-6 text-end">
                            {content.category}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item px-0">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <span className="form-label">Priority:</span>
                          </div>
                          <div className="col-6 text-end">
                            <span className="badge bg-primary p-2 px-3 rounded">
                              {" "}
                              {content.priority}{" "}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item px-0">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <span className="form-label">Status:</span>
                          </div>
                          <div className="col-6 text-end">
                            {content.status === "open" ? (
                              <span className="badge p-2 bg-success  px-3 rounded ">
                                {content.status}
                              </span>
                            ) : (
                              <span className="badge p-2 bg-danger  px-3 rounded ">
                                {content.status}
                              </span>
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item px-0">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <small>Start Date:</small>
                            <div className="h6 mb-0">Jan 10, 2022</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="comment">
                  <div className="comment-author-ava">
                    <img
                      src={
                        "https://dashboard.potipay.com/uploads/users/" +
                        image_user
                      }
                      alt="Avatar"
                    />
                  </div>
                  <div className="comment-body">
                    <p className="comment-text">{content.message}</p>
                    <div className="comment-footer">
                      <span className="comment-meta">
                        {currentUser.data.user.name}
                      </span>
                    </div>
                  </div>
                </div>

                {content.comments && content.comments.length > 0
                  ? Object.entries(content.comments).map((t, k) => (
                      <Comment
                        key={k}
                        username={
                          (t[1].user_id = currentUser.data.user.id
                            ? "you"
                            : "support")
                        }
                        comment={t[1].comment}
                        url={
                          (t[1].user_id = currentUser.data.user.id
                            ? image_user
                            : "Support.png")
                        }
                        img={t[1].image != null ? t[1].image : null}
                      />
                    ))
                  : ""}

                <h5 className="mb-30 padding-top-1x">Leave Message</h5>
                <Form
                  onSubmit={sendComment}
                  ref={form}
                  encType="multipart/form-data"
                >
                  <div className="form-group">
                    <textarea
                      className="form-control form-control-rounded"
                      rows="6"
                      placeholder="Write your message here..."
                      required=""
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <Input
                      className="form-control"
                      type="file"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="text-right">
                    <button
                      className="btn btn-outline-primary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      Submit Message
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
