import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getPaymentMethods } from "../actions/userAction";
import BarHistory from "../components/BarHistory";
import CardsWallet from "../components/CardsWallet";
import LoadingSpinner from "../components/LoadingSpinner";
import { checkSession } from "../functions/forms";
import { useLoding } from "../hooks/Loding";
import UserService from "../services/user.service";

const OptionsComp = (k, t) => {
  if (t[1].type_accept == "card") {
    return (
      <>
        <div className="col-sm-3 stylecards">
          <Link to={"/Card/" + t[1].id}>
            <img
              src={
                "https://dashboard.potipay.com/uploads/companies//" + t[1].image
              }
              className="rounded img-fluid"
              alt={t[1].name}
            ></img>
            <hr />
            <h5 style={{ padding: "10px 0" , textAlign:"center"}}>{t[1].name}</h5>{" "}
          </Link>
        </div>
      </>
    );
  }
};
const Cards = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { loading, setLoading } = useLoding();
  const dispatch = useDispatch();

  useEffect(() => {
    checkSession();
    setLoading(true);
    dispatch(getPaymentMethods()).then(
      (response) => {
        setPaymentMethods(response.data.companies);
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setLoading(false);

        setPaymentMethods(_content);
      }
    );
  }, []);

  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="History Payout" />

        <div className="container mt-3 p-2">
          <div className="row">
            {loading ? (
              <LoadingSpinner />
            ) : (
              Object.entries(paymentMethods).map((t, k) => OptionsComp(k, t))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
