import React from "react";
import { CheckImage } from "../../functions/forms";

function SelectWithImageComponent(props) {

  const Image = React.memo(function Image({ src }) {
    return <img src={src} width="35"
    alt="..."
    className="img-fluid rounded-circle"/>;
  }); 
  return (
    <>
      <div className="articles card">
        <div className="card-body no-padding">
          <div className="item d-flex align-items-center p-1"  >
            <div className="image " >
            <Image src={
               CheckImage(props.companyImg)   } />
               
            </div>
            <div className="text">
              <span className="companyName">
               {props.companyName} 
              </span> <br />
              <small>{props.fee} %</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectWithImageComponent;
