
import React from "react"; 
 

export function MsgAlert(props) {
    switch (props.type) {
      case "success":
        return (
          <div className="alert alert-success" role="alert">
            {props.msg}
          </div>
        );
  
      case "error":
        return (
          <div className="alert alert-danger" role="alert">
            {props.msg}
          </div>
        );
  
      default:
       return (
          <></>
        );
  
        break;
    }
  }