import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import ReactTooltip from 'react-tooltip'; 

function CardNumber(props) {
  
  return (
    <>
      <div className="col-md-3 col-sm-4 py-2" align="center">
        <div className="card" >
          <div className="card-body">
            <h6 className="mb-3 mt-1"  data-tip={props.tooltip ?? ""} ><ReactTooltip />{props.name}</h6>
            <h3 className="mb-0" >{props.number === "NaN" ?   <LoadingSpinner height={50}  width={50} /> :props.number }</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardNumber;
