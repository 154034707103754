import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  BLOCK,
  VERIFY,
  HIDE_MENU
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const verify = user?.data?.user?.email_verified_at === null ? 0 : 1; 
const initialState = 
user
  ? { isLoggedIn: true, user , stateUser:user?.data?.user?.state, 
    stateVerify:verify , hideMenu:false
  }
  : { isLoggedIn: false, user: null };


export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        stateUser:payload.stateUser,
        stateVerify:payload.stateVerify
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
      case BLOCK:
      return {
        ...state,
        stateUser:payload.state,
        stateVerify:payload.verify
      };
      
      case VERIFY:
      return {
        ...state,
        user: payload.user,
        stateVerify:payload.verify
      
      };
      case HIDE_MENU:
      return {
        ...state,
        hideMenu:payload.hideMenu
      
      };
    default:
      return state;
  }
}
