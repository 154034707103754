import React, { useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import { updatePassword } from "../actions/userAction";
import { updateUser } from "../actions/userAction";
import InputComponent from "../components/form/InputComponent";
import { useLoding } from "../hooks/Loding";
import { usePhone } from "../hooks/forms/usePhone";
import { useUsername } from "../hooks/auth/useUsername";
import { useLogEmail } from "../hooks/auth/LogEmail";
import { useImage } from "../hooks/forms/useImage";
import { useOldPassword } from "../hooks/auth/useOldPassword";
import { useCPassword } from "../hooks/auth/useCPassword";
import { useLogPassword } from "../hooks/auth/LogPassword";
import toast, { Toaster } from "react-hot-toast";
import { checkSession } from "../functions/forms";
import { useState } from "react";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Profile = () => {
  const form = useRef();
  const { loading, setLoading } = useLoding();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { email, setEmail, changeEmail } = useLogEmail();
  const { username, setUsername, changeUsername } = useUsername();
  const { phone, setPhone, changePhone } = usePhone();
  const { image, changeImage } = useImage();
  const { oldPassword, changeOldPassword } = useOldPassword();
  const { c_password, changeCPassword } = useCPassword();
  const { password, changePassword } = useLogPassword();
  const dispatch = useDispatch();
  const [urlPostback, setUrlPostback] = useState();

  const { isLoggedIn } = useSelector((state) => state.auth);
  //

  useEffect(() => {
    checkSession();
    if (!currentUser) return;
    setEmail(currentUser.data.user.email);
    setUsername(currentUser.data.user.name);
    setPhone(currentUser.data.user.phone);
    return () => {};
  }, []);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const handlePassword = (e) => {
    e.preventDefault();
    checkSession();
    setLoading(true);
    if (password) {
      dispatch(updatePassword(oldPassword, password, c_password))
        .then(() => {
          toast.success("Successfully update password!");

          setLoading(false);
          // props.history.push("/profile");
          // window.location.reload();
        })
        .catch(() => {
          toast.error("sorry , an error occurred 73!");
          setLoading(false);
        });
    }
  };

  const changeUrlPostback = (e) => {
    const url = e.target.value;
    setUrlPostback(url);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (username && email) {
      dispatch(updateUser(username, email, image, phone,urlPostback))
        .then((response) => {
          if (response.success) {
            localStorage.setItem("user", JSON.stringify(response));
            toast.success(response.message);
            localStorage.setItem("timer", Date.now() + 3600 * 1000);
          }
          setLoading(false);
          // props.history.push("/profile");
          // window.location.reload();
        })
        .catch(() => {
          toast.error("sorry , an error occurred 99 !");
          setLoading(false);
        });
    }
  };
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <div className="dash-container">
        <div className="dash-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="page-header-title">
                    <h4 className="m-b-10"> Profile Account</h4>
                  </div>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="https://demo.rajodiya.com/erpgo-saas/account-dashboard">
                        Dashboard
                      </a>
                    </li>
                    <li className="breadcrumb-item">Profile</li>
                  </ul>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3">
              <div className="card sticky-top" style={{ top: "30px" }}>
                <div
                  className="list-group list-group-flush"
                  id="useradd-sidenav"
                >
                  <a
                    href="#personal_info"
                    className="list-group-item list-group-item-action border-0"
                  >
                    Personal Info{" "}
                    <div className="float-end">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </a>

                  <a
                    href="#change_password"
                    className="list-group-item list-group-item-action border-0"
                  >
                    Change Password
                    <div className="float-end">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </a>

                  <a
                    href="#company"
                    className="list-group-item list-group-item-action border-0"
                  >
                    company
                    <div className="float-end">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div id="personal_info" className="card">
                <div className="card-header">
                  <h5>Personal Info</h5>
                </div>
                <div className="card-body">
                  <Form onSubmit={handleUpdate} ref={form}>
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <InputComponent
                            label="Name"
                            className="form-control"
                            type="text"
                            name="name"
                            value={username}
                            onChange={changeUsername}
                            placeholder="Enter Your Name"
                            validations={[required]}
                            autocomplete="name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <InputComponent
                            label="Email"
                            className="form-control"
                            type="email"
                            name="email"
                            value={email}
                            onChange={changeEmail}
                            placeholder="Enter Your Email"
                            validations={[required]}
                            autocomplete="email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <InputComponent
                            label="Email"
                            className="form-control"
                            type="text"
                            name="phone"
                            value={phone == null ? "" : phone}
                            onChange={changePhone}
                            placeholder="Enter Your Number"
                            validations={[required]}
                            autocomplete="phone"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <InputComponent
                            label="url postback"
                            className="form-control"
                            type="text"
                            name="url_postback"
                            value={urlPostback}
                            onChange={changeUrlPostback}
                            placeholder="https://www.Example.com/postback"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="choose-files">
                            <label for="avatar">
                              <div className=" bg-primary profile_update">
                                {" "}
                                <i className="ti ti-upload px-1"></i>Choose file
                                here
                              </div>
                              <input
                                type="file"
                                className="form-control file"
                                onChange={changeImage}
                                name="image"
                                id="avatar"
                                data-filename="profile_update"
                              />
                            </label>
                          </div>

                          <span className="text-xs text-muted">
                            Please upload a valid image file. Size of image
                            should not be more than 2MB.
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-12 text-end">
                        <button
                          className="btn btn-print-invoice  btn-primary m-r-10"
                          disabled={loading}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>Save Changes</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div id="change_password" className="card mt-3">
                <div className="card-header">
                  <h5>Change Password</h5>
                </div>
                <div className="card-body">
                  <Form onSubmit={handlePassword} ref={form}>
                    <div className="col-lg-6 col-sm-6 form-group">
                      <InputComponent
                        label="Old Password"
                        className="form-control"
                        type="password"
                        name="old_password"
                        validations={[required]}
                        value={oldPassword}
                        onChange={changeOldPassword}
                        autocomplete="old_password"
                        placeholder="Enter Old Password"
                      />
                    </div>

                    <div className="col-lg-6 col-sm-6 form-group">
                      <InputComponent
                        label="Password"
                        className="form-control"
                        type="password"
                        name="password"
                        validations={[required]}
                        value={password}
                        onChange={changePassword}
                        autocomplete="new-password"
                        placeholder="Enter Your Password"
                      />
                    </div>
                    <div className="col-lg-6 col-sm-6 form-group">
                      <InputComponent
                        label="New Confirm Password"
                        className="form-control"
                        type="password"
                        name="c_password"
                        validations={[required]}
                        value={c_password}
                        onChange={changeCPassword}
                        autocomplete="new-password"
                        placeholder="Enter Your Password"
                      />
                    </div>
                    <div className="col-lg-12 text-end">
                      <input
                        type="submit"
                        value="Change Password"
                        className="btn btn-print-invoice  btn-primary m-r-10"
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="commonModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="body"></div>
          </div>
        </div>
      </div>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ "z-index": " 99999" }}
      >
        <div
          id="liveToast"
          className="toast text-white  fade"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body"> </div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
};

export default Profile;

{
  /* <header className="jumbotron">
              <h3>
                <strong>{currentUser.data.user.name}</strong> Profile
              </h3>
            </header>
            <p>
              <strong>Token:</strong> {currentUser.data.token.substring(0, 20)}{" "}
              ...{" "}
              {currentUser.data.token.substr(
                currentUser.data.token.length - 20
              )}
            </p>
            <p>
              <strong>Id:</strong> {currentUser.data.user.id}
            </p>
            <p>
              <strong>Email:</strong> {currentUser.data.user.email}
            </p>
            <p>
              <strong>iimage:</strong> {currentUser.data.user.image}
            </p>
            <strong>Authorities:</strong> */
}
{
  /* <ul>
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      </ul> */
}
