import React, { useEffect } from "react"; 
import { checkSession } from "../../functions/forms";
import Authenticated from "./docu/Authenticated";
import Introduction from "./docu/Introduction";
import Postback from "./docu/Postback";
import SendReward from "./docu/SendReward";


const Document = () => {
  useEffect(() => {
    checkSession();
  });
  return (
    <div className="dash-content mt-5"> 
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-xl-3">
              <div className="card sticky-top">
                <div className="list-group list-group-flush" id="useradd-sidenav">
                  <a
                    href="#useradd-1"
                    className="list-group-item list-group-item-action border-0  "
                  >
                    Introduction
                    <div className="float-end">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </a>
                  <a
                    href="#useradd-2"
                    className="list-group-item list-group-item-action border-0"
                  >
                    authenticated
                    <div className="float-end">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </a>
                  <a
                    href="#send_reward"
                    className="list-group-item list-group-item-action border-0"
                  >
                    Send the reward
                    <div className="float-end">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </a>
                  <a
                    href="#postback"
                    className="list-group-item list-group-item-action border-0"
                  >
                    Postback
                    <div className="float-end">
                      <i className="ti ti-chevron-right"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-xl-9">
              <div id="useradd-1" className="card">
                <Introduction />
              </div>
              <div id="useradd-2" className="card">
                <Authenticated />
              </div>
              <div id="send_reward" className="card">
                <SendReward />
              </div>
              
              <div id="postback" className="card">
                <Postback />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Document;
