
import React from "react"; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalCopyEmail(props) {
  return( <>
            <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Give this email to the sender</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.email}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={props.handleCopy}>
           copy your Email {props.copied}
          </Button>
        </Modal.Footer>
      </Modal>
  </>);
}
export default ModalCopyEmail;