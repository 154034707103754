import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { payout, payoutHaram } from "../actions/payAction";
import Form from "react-validation/build/form";
import BarHistory from "../components/BarHistory";
import InputComponent from "../components/form/InputComponent";
import { useLoding } from "../hooks/Loding";
import { useNote } from "../hooks/forms/useNote";
import { useReceiver } from "../hooks/forms/useRecever";
import { usePhone } from "../hooks/forms/usePhone";
import { useCity } from "../hooks/forms/useCity";
import toast, { Toaster } from "react-hot-toast";
import CardsWallet from "../components/CardsWallet";

import { checkSession, getInput,CheckImage } from "../functions/forms";
import { getPaymentMethods } from "../actions/userAction";
import Dropdown from "react-bootstrap/Dropdown";
import SelectWithImageComponent from "../components/form/SelectWithImageComponent";
import AmountComponent from "../components/AmountComponent";
import TotalComponent from "../components/TotalComponent";
import LoadingSpinner from "../components/LoadingSpinner";
import { useCallback } from "react";

const Payout = () => {
  const form = useRef();
  const dispatch = useDispatch();
  // logding
  const { loading, setLoading } = useLoding();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [loadCompany, setLoadCompany] = useState(false);

  const [amountAfterFee, setAmountAfterFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // form
  const [companyName, setCompanyName] = useState("");
  const [companyImg, setCompanyImg] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [specialFee, setSpecialFee] = useState([]);
  const { note, changeNote } = useNote();
  const [amount, setAmount] = useState();
  const { phone, changePhone } = usePhone();
  const { city, changeCity } = useCity();
  const { receiver, changeReceiver } = useReceiver();
  const [fee, setFee] = useState(0);
  const [msgSelect, setMsgSelect] = useState("loading...");

  //is -- check
  const [isHaram, setIsHaram] = useState();

  const changeSelectCompany = (val) => {
    checkSession();

    setIsHaram(false);
    setCompanyName("");
    if (val === "") return;
    setCompanyName((s) => paymentMethods[val].hash_name); // set hashname company
    setCompanyImg((s) => paymentMethods[val].image);

    let new_fee = check(specialFee, paymentMethods[val].hash_name);
    // paymentMethods[val].hash_name
    // if(specialFee)
    let fee_now = paymentMethods[val].fee_personal;
    if (!new_fee == false) {
      fee_now = new_fee;
      setFee((s) => new_fee);
    } else {
      setFee((s) => paymentMethods[val].fee_personal);
    }
    // re calc total
    const total = (amountAfterFee * 100) / (100 - fee_now);

    setAmount(amountAfterFee);
    setTotalAmount(total.toFixed(2));
    // is haram show inputs haram
    if (paymentMethods[val].hash_name === "Haram") {
      setIsHaram(true);
    } else {
      setIsHaram(false);
    }
  };

  function check(arr, el) {
    let found = false;
    arr.forEach((element) => {
      if (element["name_company"] === el) {
        found = element["new_fee"];
      }
    });
    return found;
  }
  function getFeeAll(val) {
    let new_fee1 = check(specialFee, paymentMethods[val].hash_name);

    let fee_now = paymentMethods[val].fee_personal;

    if (!new_fee1 == false) {
      fee_now = new_fee1;
    }
    return fee_now;
  }

  useEffect(() => {
    checkSession();
    dispatch(getPaymentMethods()).then(
      (response) => {
        setPaymentMethods(response.data.companies);
        setSpecialFee(response.data.DiscountFee);

        const found = response.data.companies.find((obj) => {
          return obj.name === "PotiPay";
        });
        setCompanyName((s) => found.hash_name); // set hashname company
        setCompanyImg((s) => found.image);

        let new_fee = check(specialFee, found.hash_name);

        // if(specialFee)
        if (!new_fee == false) {
          setFee((s) => new_fee);
        } else {
          setFee(found.fee_personal);
        }
        setLoadCompany(true);
        setMsgSelect("");
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        toast.error("sorry , an error occurred !");
        setMsgSelect("sorry , an error occurred try aging ");
        setPaymentMethods(_content);
      }
    );
  }, []);
  const CustomToggle = useCallback(React.forwardRef(({ children, onClick }, ref) => (
    <a
      className="form-select"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )),[]);
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  const handlePayout = (e) => {
    e.preventDefault();
    // validate All input is requerd
    form.current.validateAll();
    // set lodding
    if (companyName !== "" && receiver !== "") {
      if (amount < 0.02) {
        toast.error("error : You must specify an amount!");
      } else {
        setLoading(true);
        if (isHaram) {
          dispatch(
            payoutHaram(companyName, amount, receiver, note, phone, city)
          )
            .then((response) => { 
              if (response.data.success) {
                toast.success(response.data.message);
              }
              setLoading(false);
            })
            .catch((e) => { 
              toast.error("sorry , an error occurred ! ");
              console.error(e);
              setLoading(false);
            });
        }else {
          dispatch(payout(companyName, 0, amount, receiver, note))
            .then((response) => { 
              console.log(response.data);
              if (response.data.success) {
                toast.success(response.data.message);
              } else {
                toast.error(response.data.message);
              }
              setLoading(false);
              // props.history.push("/profile");
              // window.location.reload();
            })
            .catch((error) => {
              toast.error("sorry , an error occurred try aging !");
              // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
      }
      console.log(error.config);
              setLoading(false);
            });
        }
      }
    }
  };

   

  function changeAmountAfterFeeToCalc(e) {
    const val = e.target.value;
    setAmountAfterFee((s) => val);
    const total = (val * 100) / (100 - fee);

    var fixedNum = parseFloat(total).toFixed(2);
    setAmount(fixedNum);

    setTotalAmount(fixedNum);
  }

  function changeTotalAmountToCalc(e) {
    const val = e.target.value;
    setTotalAmount(val);
    const total = val - val * (fee / 100);

    var fixedNum = parseFloat(total).toFixed(2);
    setAmount(fixedNum);
    setAmountAfterFee(fixedNum);
  }

   
  return (
    <>
      <div className="dash-container">
        <div className="dash-content">
          <BarHistory namePage="Payout" />
          <div className="row">
            <CardsWallet />
          </div>

          <div className="row">
            <div className=" col-md-1"></div>
            <div className=" col-md-10 p-4 border-top">
              <Form onSubmit={handlePayout} ref={form}>
                <div> Choose system </div>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {msgSelect == "" ? (
                      <>
                        <div className="articles card">
                          <div className="card-body no-padding">
                            <div className="item d-flex align-items-center p-1">
                              <div
                                className="image "
                                style={{ padding: "inherit" }}
                              >
                                  

                                <img
                                   src={
                                    CheckImage(companyImg) 
                                  } 
                                  width="35"
                                  alt="..."
                                  className="img-fluid rounded-circle"
                                />
                              </div>
                              <div className="text">
                                <span className="companyName">
                                  {companyName}
                                </span><br />
                                <small>{fee} %</small>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </>
                    ) : (
                      <LoadingSpinner />
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="col-12" style={{ margin: 0 }}>
                    <div className="container">
                      <div className="row">
                        {loadCompany
                          ? Object.entries(paymentMethods).map((t, k) =>
                              t[1].type_accept != "card" ? (
                                <Dropdown.Item
                                  key={k}
                                  className="col-6  padding-10"
                                  onClick={() => changeSelectCompany(t[0])}
                                >
                                  <SelectWithImageComponent
                                    companyImg={t[1].image}
                                    companyName={t[1].name}
                                    fee={getFeeAll(t[0])}
                                  />
                                </Dropdown.Item>
                              ) : (
                                ""
                              )
                            )
                          : ""}
                      </div>
                    </div>
                  </Dropdown.Menu>{" "}
                </Dropdown>
                {/* <InputComponent
                  label="Amount"
                  type="number"
                  className="form-control heightInput"
                  name="amount"
                  validations={[required]}
                  
                 
                  placeholder="amount"
                /> */}

                <div className="pt-2">
                  <AmountComponent
                    value={amountAfterFee}
                    onChange={changeAmountAfterFeeToCalc}
                  />
                </div>
                <div className="pt-2">
                  <TotalComponent
                    value={totalAmount}
                    onChange={changeTotalAmountToCalc}
                  />
                </div>

                {getInput(companyName, receiver, changeReceiver)}

                {isHaram && (
                  <>
                    <InputComponent
                      label="City"
                      className="form-control heightInput"
                      type="text"
                      name="city"
                      value={city}
                      onChange={changeCity}
                      placeholder="city"
                    />

                    <InputComponent
                      label="Phone"
                      className="form-control heightInput"
                      type="text"
                      name="phone"
                      value={phone}
                      onChange={changePhone}
                      placeholder="number phone"
                    />
                  </>
                )}

                <InputComponent
                  label="Note"
                  className="form-control heightInput"
                  type="text"
                  name="note"
                  value={note}
                  onChange={changeNote}
                  placeholder="note"
                />

                <div className=" py-3 col-3">
                  <button
                    className="btn-login btn btn-primary btn-block mt-2 login-do-btn"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Send</span>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
};

export default Payout;
