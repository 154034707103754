


import React from "react"; 
import InputComponent from "../form/InputComponent";

function InputPhone(props) {
  return (
    <>
     <InputComponent
      label="number phone receiver"
      type="tel"
      className="form-control heightInput"
      name="receiver" 
      value={props.value}
      onChange={props.changeValue} 
      placeholder={props.placeholder}
      maxlength={props.maxlength ?? "10"}
      required
      />
    </>
  );
}

export default InputPhone;
 