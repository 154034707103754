import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import {
  getOneRequestByID,
  getStatisticsUser,
  sendCommentToApi,
} from "../actions/userAction";
import UserService from "../services/user.service";
import BarHistory from "../components/BarHistory";
import Comment from "../components/Comment";
import { checkSession } from "../functions/forms";
import LoadingSpinner from "../components/LoadingSpinner";

const Requests = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [company, setCompany] = useState([]);
  const [receiver, setReceiver] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  let { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    getDataAPi(id);
  }, []);
  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const getDataAPi = (id) => {
    setLoading(true);
    dispatch(getOneRequestByID(id)).then(
      (response) => {
        if(response.data.success){ 
        setHistory((state) => {
          return response.data.data;
        });
        setCompany(response.data.data.company);
        setReceiver(response.data.data.receiver);
        setLoading(false);
        }
        
      },
      (error) => {
        setLoading(false);
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  };

  function newPageImage(source) {
    const fileName = source.split("/").pop();
    var el = document.createElement("a");
    el.setAttribute(
      "href",
      "https://dashboard.potipay.com/admin/getNotyHaram/" + source
    );
    el.setAttribute("target", "_blank");
    el.setAttribute("download", fileName);
    document.body.appendChild(el);
    el.click();
  }
  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="Request" />
       {loading ?  <LoadingSpinner />: <ul className="list-group">
          <li className="list-group-item">ID : {history.id}</li>
          <li className="list-group-item">company : {company}</li>
          <li className="list-group-item">
            Amount : {history.addOrRemoveAmount == "add" ? "+" : "-"}{" "}
            {history.amount}
          </li>
          <li className="list-group-item">old balace : {history.old_balace}</li>
          <li className="list-group-item">state : {history.state}</li>
          <li className="list-group-item">Receiver : {receiver}</li>
          <li className="list-group-item">note : {history.note}</li>
          <li className="list-group-item">
            {history.image ? (
              <>
                {" "}
                <button onClick={() => newPageImage(history.image)}>
                  <span className="p-2 px-3 rounded badge bg-success">
                    <i className="ti ti-arrow-bar-to-down "></i>
                  </span>
                </button>
              </>
            ) : (
              <></>
            )}
          </li>
          <li className="list-group-item">Date : {history.date}</li>
        </ul> }
      </div>
    </div>
  );
};

export default Requests;
