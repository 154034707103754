import React from "react";
import { financialFloat } from "../functions/forms";
import LoadingSpinner from "./LoadingSpinner";

function getStyle(state) {
  switch (state) {
    case "SUCCESS":
      return "p-2   rounded badge bg-success";

    case "PENDING":
      return "p-2   rounded badge bg-secondary";

    case "FAILED":
      return "p-2   rounded badge bg-danger";

    default:
      return "p-2 rounded badge bg-info";
  }
}
function TableHistoryApi({ arr,isLoading }) {
  return (
    <>
      <div className="col-xxl-12">
        <div className="card" style={{ overflow: "hidden" }}>
          <div className="card-header">
            <h5 className="mt-1  ">last requests</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table Business" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Company</th>
                    <th>App Name</th>
                    <th>Amount</th>
                    <th>Receiver</th>
                    <th> status </th> 
                    <th> note </th> 
                    <th>Date </th>  
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? <LoadingSpinner /> :arr.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.company}</td>
                      <td>{item.app_name}</td>
                      <td> {financialFloat(item.amount)}</td>
                      <td>{item.receiver}</td>
                      <td>
                        <span className={getStyle(item.state)}>
                        {item.state}
                        </span>
                        {/* batch_status */}
                      </td> 
                      <td>{item.note}</td> 
                      <td>{item.date}</td>  
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableHistoryApi;
