import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";
import { useLogEmail } from "../hooks/auth/LogEmail";
import { useLogPassword } from "../hooks/auth/LogPassword";
import { useLoding } from "../hooks/Loding";
  


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const { email, changeEmail } = useLogEmail();
  const { password, changePassword } = useLogPassword();
  const { loading, setLoading } = useLoding(); 
  const { isLoggedIn } = useSelector((state) => state.auth);  
  const { message } = useSelector((state) => state.message); 
  const dispatch = useDispatch();  
  useEffect(() => { 
    try {
      const last_time =  localStorage.getItem('timer') ;   
     if(last_time < Date.now()){
      window.localStorage.clear(); //clear all localstorage
     
     }
     else{
      if (isLoggedIn) {
        return <Redirect to="/home" />;
      }
     }
    } catch (error) {
      
    }
    
    }, []);
  const handleLogin = (e) => {
    e.preventDefault(); 
    setLoading(true); 
    form.current.validateAll(); 
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(email, password))
        .then((data) => { 
          if(data.data.success){
            window.location.href = "/home";
          }
            // 
          // history.push("/home");
          // return <Redirect to="/home" />;
          // window.location.href = '/home'; //re
            
        })
        .catch(() => {
         
          setLoading(false);
        });
    } else {
   
      setLoading(false);
    }
  };

 

  return (
    <>
      <div className="card">
        <div className="row align-items-center text-start">
          <div className="col-xl-6">
            <div className="card-body">
              <div className="">
                <h2 className="mb-3 f-w-600">Login</h2>
              </div>
              {message && (
                    <div className="form-group pt-1">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
              <Form onSubmit={handleLogin} ref={form}>
                <div className="">
                  <div className="form-group mb-3">
                    <label htmlFor="email">Email</label>
                    <Input
                      className="form-control"
                      type="email"
                      name="email"
                      autocomplete="email"
                      value={email}
                      onChange={changeEmail}
                      validations={[required]}
                      autofocus
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={changePassword}
                      validations={[required]}
                      autocomplete="current-password"
                    />
                  </div>

                  <div className="form-group mb-4">
                    <a
                      href="https://demo.rajodiya.com/erpgo-saas/forgot-password"
                      className="text-xs"
                    >
                      Forgot Your Password?
                    </a>
                  </div>
                  <div className="d-grid">
                    <button
                      className="btn-login btn btn-primary btn-block mt-2 login-do-btn"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                  </div>
                
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  <p className="my-4 text-center">
                    Don&#039;t have an account?
                    <Link to={"/Register"} className="text-primary">
                      Register
                    </Link>
                  </p>
                </div>
              </Form>
            </div>
          </div>
          <div className="col-xl-6 img-card-side">
            <div className="auth-img-content">
              <img
                src="https://demo.rajodiya.com/erpgo-saas/assets/images/auth/img-auth-3.svg"
                alt=""
                className="img-fluid"
              />
              <h3 className="text-white mb-4 mt-5">
                “Attention is the new currency”
              </h3>
              <p className="text-white">
                The more effortless the writing looks, the more effort the
                writer actually put into the process.
              </p>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
};

export default Login;
