import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Redirect } from "react-router-dom";
import { logout } from "../actions/auth";
import { make_notifition_read } from "../actions/userAction";
import Verifiction from "../components/Verifiction";

function Navbar() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { stateUser } = useSelector((state) => state.auth);
  const { stateVerify } = useSelector((state) => state.auth);


  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  let image = "default.png";
  let noty = {};
  const ref = useRef(null);
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

 const closeMenu =()=>{
  rmovermenu();
  document.querySelector(".hamburger").classList.remove("is-active");
 }
  const changeMenu = (event) => {
   
    document
      .querySelector(".dash-sidebar")
      .classList.add("dash-over-menu-active");
    document
      .querySelector(".dash-sidebar")
      .insertAdjacentHTML("beforeend", '<div class="dash-menu-overlay"></div>');
    document
      .querySelector(".dash-menu-overlay")
      .addEventListener("click", function () {
        rmovermenu();
        document.querySelector(".hamburger").classList.remove("is-active");
      });

   
  };
  function rmovermenu() {
    document
      .querySelector(".dash-sidebar")
      .classList.remove("dash-over-menu-active");
    if (document.querySelector(".topbar")) {
      document.querySelector(".topbar").classList.remove("mob-sidebar-active");
    }
    try {
      document.querySelector(".dash-menu-overlay").remove();
    } catch (error) {
      
    }
   
  }

  if (isLoggedIn) {
    image = currentUser?.data?.user?.image
      ? currentUser?.data?.user?.image
      : image;
    noty = currentUser?.data?.Noty;
  } else {
    return <Redirect to="/" />;
  }

  function setAsRead(e) {
    if (!e.currentTarget.classList.contains("show")) {
      e.currentTarget.classList.remove("show");
    }
    if (currentUser?.data?.countNoty > 0) {
      dispatch(make_notifition_read()).then(
        (response) => {},
        (error) => {  
        }
      );
    }
  }

  return (
    <>
    
      <header className="dash-header  transprent-bg ">
        <div className="header-wrapper">
          <div className="me-auto dash-mob-drp">
            <ul className="list-unstyled">
              <li className="dash-h-item  mob-hamburger" onClick={changeMenu}  >
                <a href="#" className="dash-head-link" id="mobile-collapse">
                  <div className="hamburger hamburger--arrowturn">
                    <div className="hamburger-box">
                      <div className="hamburger-inner"></div>
                    </div>
                  </div>
                </a>
              </li>

              <li className="dropdown dash-h-item drp-company">
                <a
                  className="dash-head-link dropdown-toggle arrow-none me-0"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <span className="theme-avtar">
                    <img
                      src={
                        "https://dashboard.potipay.com/uploads/users//" + image
                      }
                      className="img-fluid rounded-circle"
                    />
                  </span>
                  <span className="hide-mob ms-2">
                    Hi,
                    {isLoggedIn ? currentUser?.data?.user?.name : ""}!
                  </span>
                  <i className="ti ti-chevron-down drp-arrow nocolor hide-mob"></i>
                </a>
                <div className="dropdown-menu dash-h-dropdown">
                  <Link to={"/Profile"} className="dropdown-item dropdown-item-menu">
                    <i className="ti ti-user"></i>
                    <span>profile</span>
                  </Link>

                  <a className="dropdown-item  dropdown-item-menu" onClick={logOut}>
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="ms-auto">
            <ul className="list-unstyled">
              <li className="dropdown dash-h-item drp-language">
                <a
                  className={
                    currentUser?.data?.countNoty > 0
                      ? "badge badge-danger badge-counter  text-light dash-head-link dropdown-toggle arrow-none me-0 "
                      : "badge   badge-counter text-secondary dash-head-link dropdown-toggle arrow-none me-0 "
                  }
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                  onClick={setAsRead}
                >
                  <span className="drp-text hide-mob">
                    <i className="ti ti-bell" style={{ fontSize: "18px" }}></i>
                  </span>
                  <span>{currentUser?.data?.countNoty}</span>
                </a>
                <div className="dropdown-menu dash-h-dropdown dropdown-menu-end notyList">
                  <h6 className="dropdown-header">Message Center</h6>

                  {currentUser?.data?.Noty ? (
                    Object.entries(noty).map((t, k) => (
                      <a
                        key={k}
                        className="dropdown-item d-flex align-items-center text-wrap"
                      >
                        <div className="font-weight-bold">
                          <div className="text-truncate  text-wrap">
                            {t[1].data.message}
                          </div>
                          <div className="small text-gray-500">
                            {/* {t[1].data.from} */}
                          </div>
                        </div>
                      </a>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </li>

              <li className=" dash-h-item mx-3 "></li>
            </ul>
          </div>
        </div>
        {stateUser ? !stateVerify ? <Verifiction /> : <></> : <></>}
      </header>
      <nav
        ref={ref}
        className="dash-sidebar light-sidebar transprent-bg"
        id="sideMenu"
      >
        <div className="navbar-wrapper">
          <div className="m-header main-logo" >
            <a href="#" className="b-brand">
              <img
                src="../assets/img/logo-dark.png"
                alt="ERPGo Saas"
                className="logo logo-lg"
              />
            </a>
          </div>

          <div className="navbar-content">
            <ul>
              <li className="dash-item dash-hasmenu " onClick={closeMenu}>
                <NavLink to={"/home"} className="dash-link " activeClassName="activeMenu">
                  <span className="dash-micon">
                    <i className="ti ti-home"></i>
                  </span>
                  <span className="dash-mtext">Home</span>
                </NavLink>
              </li>

              {currentUser && (
                <>
                  {/* <li className="dash-item dash-hasmenu" onClick={closeMenu}>
                    <NavLink to={"/Wallet"} className="dash-link"  activeClassName="activeMenu">
                      <span className="dash-micon">
                        <i className="ti ti-wallet"></i>
                      </span>
                      <span className="dash-mtext"> Wallet </span>
                    </NavLink>
                  </li> */}
                  
                  <li className="dash-item dash-hasmenu" onClick={closeMenu}>
                    <NavLink to={"/Cards"} className="dash-link"  activeClassName="activeMenu">
                      <span className="dash-micon">
                        <i className="ti ti-building-store"></i>
                      </span>
                      <span className="dash-mtext"> Store </span>
                    </NavLink>
                  </li>
                  <li className="dash-item dash-hasmenu" onClick={closeMenu}>
                    <NavLink to={"/Tickets"} className="dash-link" activeClassName="activeMenu">
                      <span className="dash-micon">
                        <i className="ti ti-ticket"></i>
                      </span>
                      <span className="dash-mtext"> Tickets </span>
                    </NavLink>
                  </li>

                  <li className="dash-item dash-hasmenu"  onClick={closeMenu}>
                    <NavLink to={"/Purchases"} className="dash-link"  activeClassName="activeMenu">
                      <span className="dash-micon">
                        <i className="ti ti-checklist"></i>
                      </span>
                      <span className="dash-mtext"> Purchases </span>
                    </NavLink>
                  </li>
                  
                  {/* type_account  : {currentUser?.data?.user } */}
                  {currentUser?.data?.user?.type_account == "Business" ? (
                    <li className="dash-item dash-hasmenu" onClick={closeMenu}>
                      <NavLink to={"/Business"} className="dash-link" activeClassName="activeMenu">
                        <span className="dash-micon">
                          <i className="ti ti-businessplan"></i>
                        </span>
                        <span className="dash-mtext"> Business Area</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </>
              )}

              {currentUser ? (
                ""
              ) : (
                <div className="navbar-nav ml-auto">
                  <li className="dash-item dash-hasmenu">
                    <NavLink to={"/login"} className="dash-link" activeClassName="activeMenu">
                      <span className="dash-micon">
                        <i className="ti ti-shopping-cart-plus"></i>
                      </span>
                      <span className="dash-mtext"> Login</span>
                    </NavLink>
                  </li>

                  <li className="dash-item dash-hasmenu">
                    <NavLink to={"/register"} className="dash-link">
                      <span className="dash-micon">
                        <i className="ti ti-shopping-cart-plus"></i>
                      </span>
                      <span className="dash-mtext"> Sign Up</span>
                    </NavLink>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
