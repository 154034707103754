import React from "react"; 

function NavbarAuth({ children }) {
  return (
    <>
     
      
    </>
  );
}
export default NavbarAuth;
