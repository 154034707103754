export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  
  if (user?.data?.token) {  
    // For Spring Boot back-end 
      return {    Authorization: "Bearer " + user.data.token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
                
                 
    };

    // for Node.js Express back-end
   //  return { "x-access-token": user.accessToken };
  } else {
    
    console.log('not found user in auth'); 
    return {};
  }
}
