import React from "react"; 

function TotalComponent(props) {
  return (
    
    <div className="row pt-1">
      <div className="col-3 ">
        <p className="form-label">Total</p>
      </div>
      <div className="col-6  ">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              $
            </span>
          </div>
          <input
            type="text"
            value={props.value}
            className=" form-control heightInput "
            placeholder="total"
            aria-label="total"
            aria-describedby="basic-addon1"
            onChange={props.onChange}
          />
        </div>
      </div>
      
      <div className="col-3 ">
      <input
            type="text"
            value="USD"

            className=" form-control heightInput sizeTextInput" 
            aria-describedby="basic-addon1"
            disabled="disabled"
          />
        </div>
    </div>
  );
}

export default TotalComponent;
