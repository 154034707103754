import React, { useState, useEffect } from "react";
import BarHistory from "../components/BarHistory";
import { checkSession } from "../functions/forms";
import userService from "../services/user.service";

const getColor = (item) => {
  switch (item) {
    case "SUCCESS":
      return "p-2 px-3 rounded badge bg-success";

    case "FAILED":
      return "p-2 px-3 rounded badge bg-danger";

    case "PENDING":
      return "p-2 px-3 rounded badge bg-info";

    default:
      return "p-2 px-3 rounded badge bg-secondary";
  }
};
const HistoryPay = () => {
  const [content, setContent] = useState("");

  useEffect(() => {  
    checkSession();
    userService.getHistoryPay().then(
      (response) => { 
        setContent(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="History Payout" />
        <div className="card">
          <div className="card-body table-border-style">
            <div className="table-responsive">
              <table id="dataTable" className="table  ">
                <thead>
                  <tr>
                    <td>receiver</td>
                    <td>company</td>
                    <td>amount</td>
                    <td>Batch Status</td>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(content).map((t, k) => (
                    <tr key={k}>
                      <td>{t[1].receiver}</td>

                      <td>{t[1].company}</td>
                      <td>
                        {t[1].amount}
                        {t[1].currency}
                      </td>
                      <td>
                        <span className={getColor(t[1].batch_status)}>
                          {" "}
                          {t[1].batch_status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryPay;
