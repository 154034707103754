import React from "react";

import Footer from "./Footer";

import NavbarAuth from "./auth/NavbarAuth";

function LayoutAuth({ children }) {
  return (
    <>
      <div className="auth-wrapper auth-v3">
        <div className="bg-auth-side bg-primary"></div>
        <div className="auth-content">
          <NavbarAuth />
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    </>
  );
}
export default LayoutAuth;
