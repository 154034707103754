import React from "react"; 
import LoadingSpinner from "./LoadingSpinner";
import { financialFloat } from "../functions/forms";
import { Link } from "react-router-dom";

function getStyle(state) {
  switch (state) {
    case "SUCCESS":
    case "add":
      return "p-2 px-3 rounded badge bg-success";

    case "PENDING":
      return "p-2 px-3 rounded badge bg-secondary";

    case "FAILED":
    case "remove":
      return "p-2 px-3 rounded badge bg-danger";
 
    default:
      return "p-2 px-3 rounded badge bg-info";
  }
}

 
  
// function newPageImage(source) { 
//   const fileName = source.split('/').pop();
// 	var el = document.createElement("a");
// 	el.setAttribute("href", "https://dashboard.potipay.com/admin/getNotyHaram/"+source);
// 	el.setAttribute("target", '_blank'); 
// 	el.setAttribute("download", fileName);
// 	document.body.appendChild(el);
//  	el.click();
 
// }

function TableHistory({ arr,isLoading }) { 
  

  return (
    <>
      <div className="col-xxl-12">
        <div className="card"  style={{ overflow: "hidden" }}>
          <div className="card-header">
            <h5 className="mt-1  ">last requests</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
            {isLoading ? <LoadingSpinner /> :  
            <table className="table TableHistory" width="100%">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Method</th>
                    <th>Amount</th> 
                    <th>Status</th> 
                    <th>date</th>
                    <th>other</th>
                  </tr>
                </thead>
                <tbody> 
                 

                 {arr.length > 0 ? arr.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                     <td> <Link
                              to={"/Requests/" + item.id}
                                >
                             {item.company}
                            </Link></td>
                      <td > 
                        <span className={getStyle(item.addOrRemoveAmount)} >
                         {financialFloat(item.amount)}
                         </span>
                      </td>
                      
                      <td>
                        <span className={getStyle(item.state)}>
                          {item.state}  
                        </span>
                       
                      </td>
                     
                      <td>{item.date}</td>
                      <td>
                      {item.image ? <>
                        <a className="btn btn-success" rel="noopener noreferrer" href={"https://dashboard.potipay.com/uploads/haram/"+item.image} target="_blank" > notify </a>
                           {/* <button onClick={() => ddown(item.image)} ><span className="p-2 px-3 rounded badge bg-success"><i className="ti ti-arrow-bar-to-down "></i></span></button> */}
                       </>:<></> }
                      </td>
                    </tr>
                  )) :<></>}
                 </tbody> 
              </table>
                  
                }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableHistory;
