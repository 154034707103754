import React from "react";

function Authenticated() {
  const responseCode = `
  [ 
    {
        "id": 3,
        "name": "Payeer ",
        "hash_name": "Payeer",
        "image": "",
        "state": 1,
        "type_accept": "amount",
        "cards": []
    },
    {
        "id": 4,
        "name": "Google Play",
        "hash_name": "GooglePlay",
        "image": "8fuC5p9L_1661862672.jpg",
        "state": 1,
        "type_accept": "card",
        "cards": [
            {
                "id": 21,
                "name": "Google Play Card 5$",
                "company_id": 4,
                "amount": "5",
                "type_amount": "$",
                "price": "5.5",
                "currency": "$",
                "image": "RGNAbenM_1661863315.png",
                "state": "1"
            }
        ]
    } ,
    .....
]  
`;

  return (
    <>
      <div className="card-body">
        <h2 className="card-title">authenticated</h2>
        <p className="card-text">
          Obtain your API key and make a successful request in less than 5
          minutes.
        </p>
        <hr />
        <h6 className="card-title">Getting an API key</h6>
        <span className="card-text">
          To get an API key: <br />
          Sign up or log in to your account at: https://potipay.com/ <br />
          <br />
            note :  <br />
            When you create your account <br />
            Choose the type of business account<br />
            And write the name of your company or <br />
            application with the website link<br />
            Then agree to the terms of use<br />
            Please wait for your account to be approved<br />
          <br />
          <div class="text-center"> 
          <img src="../assets/img/register_s.png" />
          </div>
           <br />
           After confirming your account and your Email ,
           <br /> Navigate to Business Area {">"} My Apps
          <br />
          On this page you will find a list of applications and a button to add a new application
          <br />
          Click on the name of the application you want to create an api key for
          <br />
          On this page you will find several things:<br />
          At the top is the name of the application and its status if it is active/unactive/rejected or <br />
          Then you will find your website link <br />
          Then you will find your Postback link with edit button <br />
          click in button edit to add your url postback follow the picture <br />
        
          <div class="text-center">
            <img  src="../assets/img/addPostback.png" className="rounded" alt="..." />
          </div>

          <br />
          Then you will find tabs :
               - api key : to get api key for app with edit button <br />
               - order history : It contains a history of transfers requested through the api <br />
               - Postback history : When executing a transfer request from some companies, the status of the request will be pending until it is processed <br />
                  We will make a call to the postback URL that you indicated in your application and attach all the information you will need to modify your application data 
                  <br />Here you will find a record of all calls
              - tester postback : for test url postback , just write any (order id ) and select (state order ) and clik on button test ,<br />
               
            <br /> 
          example test url postback with response :
          <div class="text-center"> 
           <img src="../assets/img/tester.png" width="500" />
           </div>
        </span>
        <h6>Making your first request</h6>
        <p>
          To verify that we are authenticated, let's make a request to the API
          using curl
        </p> 
        <div className="CodeTabs CodeTabs_initial theme-light m-2">
          <div className="CodeTabs-toolbar p-2">
            <button type="button">cURL</button>
          </div>
          <div className="CodeTabs-inner">
            <pre className=" language-html">
              <code
                className="rdmd-code lang-curl theme-light"
                data-lang="curl"
                name=""
              >
                <div className="cm-s-neo">
                  <span className="cm-builtin">GET</span>{" "}
                  <span className="cm-attribute">--url</span>{" "}
                  <span className="cm-string">
                    'https://dashboard.potipay.com/api/pay_methods'
                  </span>
                  <br /> <span className="cm-attribute ml-4"> --header</span>{" "}
                  <span className="cm-string">
                    'Authorization: Bearer YOUR-API-KEY'
                  </span>
                </div>
              </code>
            </pre>
          </div>
        </div>
        <div className="alert alert-danger" role="alert">
          <p>
           
            Make sure to replace YOUR-API-KEY with the actual API key you have
            obtained above.
          </p>
        </div>
        <p>
          A successful response here will include the name of the organization
          that you signed up with 
        </p>
        <div className="CodeTabs CodeTabs_initial theme-light">
          <div className="CodeTabs-toolbar">
            <button type="button">JSON</button>
          </div>
          <div className="CodeTabs-inner">
            <pre className="language-html">
              <code>
                <div>{responseCode}</div>
              </code>
            </pre>
          </div>
        </div>
        <div className="alert alert-primary mt-2" role="alert">
          <p>
            In reply we got all payment methods <br />
           - name: to show the full name of the payment method <br />
           - hash_name: used when sending a payment request <br />
            Usage will be explained in the next step <br />
          - type_Account : There are two types of companies (money transfer :"amount" and "trans" - recharge cards :"card") through this field you can find out the type of company
              If it is  a  card type, you will find inside the card field all the cards we have <br />
          
          <br />
          - cards : It has all kinds of cards for this company <br />

          </p> 
        </div>
        <h6>cards : </h6>
        <p>
          Note in the reply there is GooglePlay To purchase or send a card to a
          person, <br />
          we will add to the transfer request the "card_id" and its value is the
          "id" of the card we received in response
          <br />
          There is an example of sending a card in the next step
        </p>
        <p> 
          Congrats, you've validated all the payment methods currently
          available!
        </p> 
      </div>
    </>
  );
}

export default Authenticated;
