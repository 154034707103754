import React, { useState, useEffect } from "react";
import { checkSession } from "../functions/forms";

import UserService from "../services/user.service";

const HistorySendBalance = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    checkSession();
    UserService.getHistorySendBalance().then(
      (response) => { 
        setContent(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="container">
      <table className="table table-bordered">
        <thead>
          <tr>
            <td>to</td>
            <td>amount</td>
            <td>note</td>
          </tr>
        </thead>
        <tbody>
          {Object.entries(content).map((t, k) => (
            <tr key={k}>
              <td>{t[1].to}</td>

              <td>{t[1].amount}</td>
              <td>{t[1].note}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HistorySendBalance;
