import React from "react";
import Navbar from "./Business/Navbar";
import Footer from "./Business/Footer";

function LayoutBusiness({ children }) {
  return (
    <>
      <div className="container">
        <Navbar />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
}
export default LayoutBusiness;
