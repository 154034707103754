
import React, { useState } from "react";
import ModalVerify from "./ModalVerify";

function Verifiction(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => { 
        setShow(false);  
  } 
  function popupCode(){
    setShow(true)
  }

  
  return (
    <div className="Verifiction_container">
        your account not verification  <ModalVerify
        show={show}   handleClose={handleClose}  />
        <button  onClick={popupCode} > verifiy now</button>
    </div>
  );
}

export default Verifiction;