
  
  import userService from "../services/user.service";
import { BLOCK, LOGIN_SUCCESS, VERIFY } from "./types";
 
  export const updateEmail = (email ) => (dispatch) => {
    return userService.updateEmail(email  ) ;
  };
  
  export const updateUser = (username,email,image,phone,urlPostback ) => (dispatch) => {
    return userService.updateUser(username,email,image,phone,urlPostback).then( 
      (response) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response },
        }); 
        return response;
      },
    );
  };
  
  export const updatePassword = (oldPassword,newPassword,c_password ) => (dispatch) => {
    return userService.updatePassword(oldPassword,newPassword,c_password) ;
  };
  
  
  export const sendCommentToApi = (ticket_id,comment,image ) => (dispatch) => {
    return userService.sendCommentToApi(ticket_id,comment,image) ;
  };

  export const sendBalanceToApi = (receiver,amount,note) => (dispatch) => {
    return userService.sendBalanceToApi(receiver,amount,note) ;
  };

  
  export const getHistorySendBalance = () => (dispatch) => {
    return userService.getHistorySendBalance() ;
  };
  
  export const getHistoryReciveBalance = () => (dispatch) => {
    return userService.getHistoryReciveBalance() ;
  };

  export const openTicketApi = (title,message) => (dispatch) => {
    return userService.openTicketApi(title,message) ;
  };

  export const getStatisticsUser = (page) => (dispatch) => {
    return userService.getStatisticsUser(page).then(

      (response) => { 
        const stateVerifyNew = response.data.DataUserWithNotify.data.user.email_verified_at ? true:false;
        const stateUserNew = response.data.DataUserWithNotify.data.user.state ? true:false;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response.data.DataUserWithNotify , stateVerify:stateVerifyNew  ,stateUser:stateUserNew},
        }); 
        
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.DataUserWithNotify)
        );
        localStorage.setItem(
          "timer",
         (Date.now()+ (3600* 1000))
        );
        
        
        return response;
        // return Promise.resolve();
      },


    );
  };
  

  export const getStatistics = (pNumber,startDate, endDate) => (dispatch) => {
    return userService.getStatistics(pNumber,startDate, endDate).then(

      (response) => { 
      
        const stateVerifyNew = response.data.DataUserWithNotify.data.user.email_verified_at ? true:false;
        const stateUserNew = response.data.DataUserWithNotify.data.user.state ? true:false;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response.data.DataUserWithNotify , stateVerify:stateVerifyNew  ,stateUser:stateUserNew},
        }); 
        
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.DataUserWithNotify)
        );
        localStorage.setItem(
          "timer",
         (Date.now()+ (3600* 1000))
        );
        
      
        return response;
        // return Promise.resolve();
      },


    );
  };


  
  
  export const getDepositHistory = (pNumber) => (dispatch) => {
    return userService.getDepositHistory(pNumber) ;
  };



  export const make_notifition_read = () => (dispatch) => {
    return userService.make_notifition_read().then(

      (response) => { 
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response.data.DataUserWithNotify },
        }); 
        return response;
        // return Promise.resolve();
      },


    );
  };

  
  export const checkCode = (code) => (dispatch) => {
    return userService.checkCode(code).then( 
      (response) => {
         
         let verify = response.data.success_code  ? 1 :0;
  
          dispatch({
            type: VERIFY,
            payload: { verify:verify},
          }); 
            
        return response;
        // return Promise.resolve();
      }
    ).catch((error) => {
       
     return error
    });
  };
  
  export const resendNewCode = () => (dispatch) => {
    return userService.resendNewCode().then( 
      (response) => {
       
        // dispatch({
        //   type: LOGIN_SUCCESS,
        //   payload: { user: response.data.DataUserWithNotify },
        // }); 
        return response;
      }
    );
  };
  
  
  
  export const getUserBoard = () => (dispatch) => {
    return userService.getUserBoard().then( 
      (response) => {
        
         if(response.data.success){
           localStorage.setItem(
          "user",
          JSON.stringify(response.data)
        );
        
        let verify = response.data.data.user.email_verified_at === null ? 0 :1;
        dispatch({
          type: BLOCK,
          payload: { state: response.data.data.user.state , verify:verify },
        }); 
         }
       
        return response;
      }
    );
  };
  
  export const getPaymentMethods = () => (dispatch) => {
    return userService.getPaymentMethods().then( 
      (response) => {  
        const stateVerifyNew = response.data.DataUserWithNotify.data.user.email_verified_at ? true:false;
        const stateUserNew = response.data.DataUserWithNotify.data.user.state ? true:false;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response.data.DataUserWithNotify , stateVerify:stateVerifyNew  ,stateUser:stateUserNew},
        }); 
        
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.DataUserWithNotify)
        );
        localStorage.setItem(
          "timer",
         (Date.now()+ (3600* 1000))
        );
        return response;
      }
    );
  };

  

  export const myTickets = () => (dispatch) => {
    return userService.myTickets().then( 
      (response) => {  
        const stateVerifyNew = response.data.DataUserWithNotify.data.user.email_verified_at ? true:false;
        const stateUserNew = response.data.DataUserWithNotify.data.user.state ? true:false;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response.data.DataUserWithNotify , stateVerify:stateVerifyNew  ,stateUser:stateUserNew},
        }); 
        
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.DataUserWithNotify)
        );
        localStorage.setItem(
          "timer",
         (Date.now()+ (3600* 1000))
        );
        return response;
      }
    );
  };

  
  export const getOneRequestByID = (id) => (dispatch) => {
    return userService.getOneRequestByID(id) ;
  };