import React from "react"; 
import LoadingSpinner from "./LoadingSpinner"; 
 
function TableRealCards({ arr,isLoading }) {  
  function showCode(id){ 
   document.getElementById(id).innerHTML = arr[id]['code'];
  }
  return (
    <>
      <div className="col-xxl-12">
        <div className="card"  style={{ overflow: "hidden" }}>
          <div className="card-header">
            <h5 className="mt-1  ">My Cards</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table Purchases" width="100%">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name Card</th>
                    <th>Price</th>
                    <th>Code</th>  
                    <th>Date</th> 
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? <LoadingSpinner /> :
                 arr.length > 0 ? arr.map((item,key) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name_card}</td>
                       <td>{item.price}</td> 
                       <td> <span id={key}>************</span> <button onClick={()=>showCode(key)} ><i className="ti ti-eye" ></i></button></td>   
                      <td>{item.date}</td> 
                    </tr>
                  )) : <p style={{"padding":"10px"}}>is empty </p>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableRealCards;
