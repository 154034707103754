import React from "react";

function Comment(props) {
  return (
    <>
      <div className="comment">
        <div className="comment-author-ava">
          {props.url && (
            <img
              src={"https://dashboard.potipay.com/uploads/users/" + props.url}
              alt="Avatar"
            />
          )}
        </div>
        <div className="comment-body">
          <p className="comment-text">{props.comment}</p>
          {props.img && (
            <img
              src={
                "https://dashboard.potipay.com/uploads/comments/" + props.img
              }
              alt="..."
              width="100"
            />
          )}
          <div className="comment-footer">
            <span className="comment-meta">{props.username}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Comment;
