import React, { useCallback, useEffect, useRef, useState } from "react";
 
import { useLoding } from "../hooks/Loding";
import { checkCode, getUserBoard, resendNewCode } from "../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useCode } from "../hooks/useCode";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Redirect, useHistory } from "react-router-dom";  
import { logout } from "../actions/auth";
import '../style_hime_ele.css'

const Verify = (props) => {
  const { loading, setLoading } = useLoding();
  const { isLoggedIn } = useSelector((state) => state.auth);  
  const { stateVerify } = useSelector((state) => state.auth);
  const { code, changeCode } = useCode();
  const form = useRef();
  const dispatch = useDispatch();
  const routerHistory = useHistory();
  const [check , setCheck]=useState(false);
  const logOut = useCallback(() => {
    dispatch(logout());
    routerHistory.push("/login"); 
  }, [dispatch]);
  

  useEffect(() => {
    getDataUser(); 
    if (!isLoggedIn) {
      return <Redirect to="/login" />;
    }
  }, []);
  const getDataUser = () => {
    dispatch(getUserBoard())
      .then((response) => { 
        if(response.data.data.user.email_verified_at != null){
            window.location.href = "/home";
        }
      })
      .catch((e) => { 
      });
  };


  const checkCodeNow = (e) => {
    e.preventDefault(); 
    setLoading(true);
    setCheck(true);
    form.current.validateAll(); 
    if (code > 0) {
      dispatch(checkCode(code))
        .then((response) => { 
          if (response.data.success_code) {
            toast.success(response.data.message);
            getDataUser(); 
            setLoading(false);
            setCheck(false);
            const timeout = setTimeout(() => {
              routerHistory.push("/home");
            }, 4000);
            return () => clearTimeout(timeout);
          
          }
          else{
            setLoading(false);
            setCheck(false);
            toast.error(response.data.message);
          }
         
        })
        .catch((e) => { 
          toast.error("sorry , an error occurred !");
          setLoading(false);
        });
    } else {
      toast.error("sorry , an error occurred !");
      setLoading(false);
    }
  };


  const resendNewCode1 = (e) => {
     
    setLoading(true);
      dispatch(resendNewCode())
        .then((response) => {
          toast.success(response.data.message);
          setLoading(false); 
        })
        .catch((e) => { 
          toast.error("sorry , an error occurred !");
          setLoading(false);
        });
    
  };

  return (
    <>
  
       
        
         
          <div className="row">
            <div className="col-sm-12">
              <div className="row"> 
                <Form onSubmit={checkCodeNow} ref={form}>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="email">Code</label>
                      <input
                        className="form-control"
                        type="text"
                        name="code"
                        value={code}
                        onChange={changeCode}
                        autofocus
                      />
                    </div>

                    <div className="d-grid">
                      <button
                        className="btn-login btn btn-primary btn-block mt-2 login-do-btn"
                        disabled={loading}
                      >
                        {check && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Check</span>
                      </button>
                    </div>
                  </div>
                </Form>
              
              </div>
               <div className="mt-3">
               <button onClick={resendNewCode1}>resend Code</button>
               </div>
            </div>
          </div> 
          <Toaster />
          <a  onClick={logOut}>
                    Logout
                  </a>
    </>
  );
};

export default Verify;
