import React, { useState } from "react";
import { checkSession } from "../../functions/forms";
import UserService from "../../services/user.service";
import toast, { Toaster } from "react-hot-toast";
import { useLoding } from "../../hooks/Loding";
import { useEffect } from "react";
import TableApps from "../../components/TableApps";

const MyApps = () => {
  const accesskey = localStorage.getItem("Accesskey");
  const [copySuccess, setCopySuccess] = useState("");
  const [textToCopy, setTextToCopy] = useState(accesskey);
  const { loading, setLoading } = useLoding();

  const [content, setContent] = useState([]);

  const [isOpened, setIsOpened] = useState(false);
  const [nameApp, setNameApp] = useState("");
  const [urlWebsite, setUrlWebsite] = useState("");

  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const getDataAPi = (pNumber) => {
    UserService.getMyApps().then(
      (response) => {
        setContent((state) => {
          return response.data.data;
        });
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setLoading(false);
        setContent(_content);
      }
    );
  };
  useEffect(() => {
    setLoading(true);
    checkSession();
    getDataAPi(1);
  }, []);

  const changeNameApp = (event) => {
    const url = event.target.value;
    setNameApp(url);
  };
  const changeUrlWebsite = (event) => {
    const url = event.target.value;
    setUrlWebsite(url);
  };

  const createNewApp = (e) => {
    e.preventDefault();
    UserService.createNewApp(nameApp, urlWebsite)
      .then((response) => {
        setContent((state) => {
          return response.data.data.apps;
        });
      })
      .catch(function (error) {});
  };

  const copy = () => {
    checkSession();
    navigator.clipboard.writeText(textToCopy);
    setCopySuccess("Copied!");
    toast.success(copySuccess);
  };

  return (
    <div className="dash-content">
      <div className="box">
        <div className="boxTitle btn btn-primary my-3" onClick={toggle}>
          create new app
        </div>
        {isOpened && (
          <div className="boxContent">
            <form onSubmit={createNewApp}>
              <div class="mb-3">
                <label htmlFor="nameApp" class="form-label">
                  Name:
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="nameApp"
                  value={nameApp}
                  onChange={changeNameApp}
                />
              </div>

              <div class="mb-3">
                <label htmlFor="urlWebsite" class="form-label">
                  Url Website:
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="urlWebsite"
                  value={urlWebsite}
                  onChange={changeUrlWebsite}
                />
              </div>

              <input
                type="submit"
                value="add new app "
                className="btn btn-bg-primary"
                disabled={loading}
              />
            </form>
          </div>
        )}
      </div>
      <TableApps arr={content} isLoading={loading} />
    </div>
  );
};

export default MyApps;
