import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BarHistory from "../components/BarHistory";
import UserService from "../services/user.service";
import { payout } from "../actions/payAction";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLoding } from "../hooks/Loding";
import { useReceiver } from "../hooks/forms/useRecever";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../components/LoadingSpinner";
import { checkSession } from "../functions/forms";

const Card = () => {
  // logding
  const { loading, setLoading } = useLoding();
  const [content, setContent] = useState("");
  const [price, setPrice] = useState(0);
  const [show, setShow] = useState(false);
  const [className] = useState("col-sm-4  m-2 styleCardWithPrice notActive");
  const [classNameActive] = useState("col-sm-4  m-2 styleCardWithPrice active1");
  const [cardIdSelected, setCardIdSelected] = useState(0);

  //form
  const { receiver, changeReceiver } = useReceiver();
  const [companyName, setCompanyName] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    checkSession();
  }, []);
  const handlePayout = () => { 
    setLoading(true);
    if (companyName !== "" && receiver !== "") {
      if (cardIdSelected == null) {
        toast.error("error : You must specify an amount or choose a card !");
      } else {
        dispatch(payout(companyName, cardIdSelected, 0, receiver, ""))
          .then((response) => { 
            if (response.data.success) {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
            setLoading(false);
            handleClose();
            // props.history.push("/profile");
            // window.location.reload();
          })
          .catch((error) => { 
            toast.error("sorry , an error occurred  !");
            setLoading(false);
          });
      }
    }
  };

  let { id } = useParams();
 
  const selectCard = (id) => { 
    setPrice(content[id].price)
    setCardIdSelected(content[id].id);
    setCompanyName(content[id].company.hash_name);

  };
  //   t[1].id
  const OptionsComp = (k, t) => { 
    return (
      <>
        <div key={k}
          className={cardIdSelected == t[1].id ? classNameActive : className}
          onClick={() => selectCard(k)}
        >  
          <img src="../assets/img/checkIcon.svg" className="checkIcon"  />
          <img
           
            src={"https://dashboard.potipay.com/uploads/cards//" + t[1].image}
            className="rounded img-fluid logoCard"
            alt={t[1].name}
          ></img>
          <span className="amountCard">{t[1].amount} {t[1].type_amount} </span>
        <hr /> <h5  style={{ padding: "10px 0" , textAlign:"center"}}>{t[1].name}</h5>
        </div>
       
      </>
    );
  };

  const getData = (id) => {
    UserService.getCardsCompany(id).then(
      (response) => {
        setContent(response.data.data); 
        console.log(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString(); 
      }
    );
  };
  useEffect(() => {
    handleClose();
    getData(id);
  }, []);

  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="History Payout" />

        <div className="container mt-3 p-2   ">
          <div className="row cards">
            {loading ? (
              <LoadingSpinner />
            ) : (
              Object.entries(content).map((t, k) => OptionsComp(k, t))
            )} 
            <div >
              
            </div>
           <div className="row">
           <div className="col-md-3 mt-5 stylePrice">
                 price : {price} $
              </div>
             
            </div> 
            <div className="col-md-3  mt-2 ">
              {" "}
              <Button
                className="px-3"
                variant="primary"
                onClick={handleShow}
                disabled={cardIdSelected > 0 ? "" : "disabled"}
              >
                buy now
              </Button>
           </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <label htmlFor="Receiver" className="form-label">
                  Receiver
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="receiver"
                  value={receiver}
                  onChange={changeReceiver}
                  placeholder="Receiver"
                  required="required"
                />
                <p>The card will be sent to this email</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={handlePayout}
                  disabled={loading ? "disabled" : false}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>buy now</span>
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Card;
