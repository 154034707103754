import axios from "axios";

const API_URL = "https://dashboard.potipay.com/api/";
const headers = {
  'Content-Type': 'application/json',
  'Authorization': 'JWT fefege...'
}

const register2 = (username, email, password ) => {

  return axios.post(API_URL + "register", {
    name:username,
    email,
    password,
    c_password:password 
  }, {
    headers: headers
  }).then((response) => { 

    if (response.data.success) { 

      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("Accesskey",  response.data.data.Accesskey );
      
      localStorage.setItem(
        "timer",
       (Date.now()+ 60*60)
      );
    }

  return response.data;
  });
};


const register = (username, email, password,typeAccount,companyNampe,urlWebsite) => {

  return axios.post(API_URL + "register", {
    name:username,
    email,
    password,
    c_password:password,
    type_account:typeAccount,
    app_name:companyNampe,
    url_website:urlWebsite
  }, {
    headers: headers
  }).then((response) => { 

    if (response.data.success) { 

      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("Accesskey",  response.data.data.Accesskey );
      
      localStorage.setItem(
        "timer",
       (Date.now()+ 60*60)
      );
    }

  return response.data;
  });
};

const login = (username, password) => { 
  return axios
    .post(API_URL + "login", {
      email:username,
      password,
    })
    .then((response) => { 
      if (response.data.success) { 
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("Accesskey",  response.data.data.Accesskey );
        
        localStorage.setItem(
          "timer",
         (Date.now()+(3600*1000))
        );
      }

    return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user"); 
};

export default {
  register,
  login,
  logout,
};
