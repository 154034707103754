import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { checkCode, resendNewCode } from "../actions/userAction"; 
import { useCode } from "../hooks/useCode"; 
import { MsgAlert } from "./MsgAlert";


function ModalVerify(props) { 
  const { code, changeCode } = useCode(); 
  const dispatch = useDispatch();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("");

  const [loading, setLoading] = useState(0);
 
  const checkCodeNow = (e) => {
    e.preventDefault();
    setLoading(1);

    if (code > 0) {
      dispatch(checkCode(code))
        .then((response) => {
           
          setMessage(response.data.message)
          setShowMsg(true)
          if (response.data.success_code) {
            setTypeAlert("success")  
            setLoading(0);
            setTimeout(() => {
              window.location.reload(false);
            }, 4000);
              
          } else {
            setTypeAlert("error")
            setLoading(0);
          }
          setTimeout(() => {
            setShowMsg(false) 
          }, 4000);
        })
        .catch((e) => {
          setTypeAlert("error")
         

          setLoading(0);
        });
    } else {
      setLoading(0);
    }
  };

  const resendNewCode1 = (e) => {
    setLoading(2);
    dispatch(resendNewCode())
      .then((response) => {
         setMessage(response.data.message)
          setShowMsg(true)
          setTypeAlert("success") 
        setLoading(0);
      })
      .catch((e) => {
        
        setLoading(0);
      });
      setTimeout(() => {
        setShowMsg(false)
      }, 4000);
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
      <Modal.Title>verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Code: </Form.Label>
            <Form.Control
              type="text"
              onChange={changeCode}
              value={code}
              placeholder="code"
            />
          </Form.Group>
          {showMsg ? <MsgAlert type={typeAlert} msg={message}   /> :<></>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={resendNewCode1} disabled={loading}
                    >
                      {loading === 2 ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ):<></>}
                      <span>resend</span>
            
           
          </Button>
          <Button variant="secondary" onClick={props.handleClose}  disabled={loading}>
            Close
          </Button>
          <Button variant="primary" onClick={checkCodeNow} disabled={loading}
                    >
                      {loading === 1 ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) :<></>}
                      <span>Verify</span>
            
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ModalVerify;
