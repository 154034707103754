import { 
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  HIDE_MENU,
} from "./types";

import AuthService from "../services/auth.service";


export const register2 = (username, email, password) => (dispatch) => {
  return AuthService.register2(username, email, password).then(
    (response) => {
      
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return response;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const register = (username, email, password,typeAccount,companyNampe,urlWebsite) => (dispatch) => {
  return AuthService.register(username, email, password,typeAccount,companyNampe,urlWebsite).then(
    (response) => {
       
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return response;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      
      if(error.response.data.errors['email'][0]){
        dispatch({
          type: SET_MESSAGE,
          payload: error.response.data.errors['email'][0],
        });
      }
      else if(error.response.data.errors['password'] ){
        dispatch({
          type: SET_MESSAGE,
          payload: error.response.data.errors['password'][0],
        });
      }
      else{
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
      } 
      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return data;
    },
    (error) => { 
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout(); 
  dispatch({
    type: LOGOUT,
  });
};

 
export const hideMenuAction = (hideMenu) => (dispatch) => {
   
  dispatch({
    type: HIDE_MENU,
    payload:{hideMenu:hideMenu}
  });
};
