import React from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

function getStyle(state) {
  switch (state) {
    case "active":
      return "p-2 px-3 rounded badge bg-success";

    case "unactive":
      return "p-2 px-3 rounded badge bg-secondary";

    case "rejected":
      return "p-2 px-3 rounded badge bg-danger";

    default:
      return "p-2 px-3 rounded badge bg-info";
  }
}
function TableApps({ arr,isLoading }) {
  return (
    <>
      <div className="col-xxl-12">
        <div className="card" style={{ overflow: "hidden" }}>
          <div className="card-header">
            <h5 className="mt-1  ">last requests</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table Business" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>app_name</th> 
                    <th>state_app</th>
                    <th>Date </th>  
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? <LoadingSpinner /> :arr.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td><Link to={"/ShowApp/"+item.id} >{item.app_name} </Link></td>
                      
                      <td>
                        <span className={getStyle(item.state_app)}>
                        {item.state_app}
                        </span>
                      </td>  
                      <td>{item.created_at}</td>  
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableApps;
