import React, { useState, useEffect, useRef } from "react";
import BarHistory from "../../components/BarHistory";
import CardNumber from "../../components/CardNumber";
import TableHistoryApi from "../../components/TableHistoryApi";
import Form from "react-validation/build/form";
import Pagination from "react-js-pagination";

import UserService from "../../services/user.service";
import InputComponent from "../../components/form/InputComponent";
import { useStartDate } from "../../hooks/useStartDate";
import { useEndDate } from "../../hooks/useEndDate";
import { checkSession } from "../../functions/forms";
import { useDispatch } from "react-redux";
import {
  getStatistics, 
} from "../../actions/userAction"; 

const BuHome = () => {
  const [content, setContent] = useState([]);
  const [history, setHistory] = useState([]);
  const [state, setData] = useState([]); 

  const [loading, setLoading] = useState(false);
  const { startDate, changeStartDate } = useStartDate();
  const { endDate, changeEndDate } = useEndDate();
  const [pNumber, setPNumber] = useState(1);
  const [tableSearch, setTableSearch] = useState(false);
  const [contentTableSearch, setContentTableSearch] = useState([]);
  const dispatch = useDispatch();
  const form = useRef();

  const getDataAPi = (pNumber) => {
    setTableSearch(false);
    UserService.getStatistics(pNumber).then(
      (response) => { 
        setContent((state) => {
          return response.data.Statis;
        });
        // setHistory((state) => { return ""} );
        setHistory((state) => {
          return response.data.history.data;
        });
        setData((state) => {
          return response.data.history;
        });
 
        setLoading(false);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setLoading(false);
        setContent(_content);
      }
    );
  };
  useEffect(() => {
    checkSession();
    getDataAPi(1);
  }, []);

  useEffect(() => {
    checkSession();
    getDataAPi(pNumber);
  }, [pNumber]);
  useEffect(() => {}, [content]);

  const handlePayout = (e) => {
    e.preventDefault();
    checkSession();
    setLoading(true);
    form.current.validateAll();

    dispatch(getStatistics(1, startDate, endDate)).then(
      (response) => { 
        setContentTableSearch((state) => {
          return response.data.history.data;
        });
        setLoading(false);
        setTableSearch(true);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setLoading(false);
        setContent(_content);
      }
    );
  };

  function financialFloat(x) {
    const number = Number.parseFloat(x).toFixed(2);
    return number;
  }

  function financialInt(x) {
    const number = Number.parseInt(x);
    return number;
  }

  return (
    <div className="dash-content ">
      <BarHistory namePage="Home" />
      <div className="row">
        <div className="row border p-2 m-2">
          <h3>Filter</h3>
          <Form onSubmit={handlePayout} ref={form} className="row g-3  my-3">
            <div class="col-md-4">
              <InputComponent
                label="Start Date"
                className="form-control"
                type="date"
                name="start_date"
                value={startDate}
                onChange={changeStartDate}
                placeholder="Start Date Date"
              />
            </div>
            <div class="col-md-4">
              <InputComponent
                label="End Date"
                className="form-control"
                type="date"
                name="end_date"
                value={endDate}
                onChange={changeEndDate}
                placeholder="End Date"
              />
            </div>
            <div class="col-md-4">
              <button className=" btn btn-primary " disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Filter</span>
              </button>
            </div>
          </Form>
        </div>
        <div className="row">
          <CardNumber
            icon="ti ti-report-money"
            upper="totel"
            name="Total"
            number={financialFloat(content.sum_all_amount_requests)}
          />
          <CardNumber
            icon="ti ti-report-money"
            upper="totel"
            name="Success"
            number={financialFloat(content.sum_amount_success)}
          />
          <CardNumber
            icon="ti ti-report-money"
            upper="totel"
            name="Processing"
            number={financialFloat(content.sum_amount_pending)}
            tooltip="Processing in the transfer company"
          />

          <CardNumber
            icon="ti ti-report-money"
            upper="totel"
            name="failed"
            number={financialFloat(content.sum_amount_failed)}
          />
          <CardNumber
            icon="ti ti-report-money"
            upper="totel"
            name="Balance"
            number={financialFloat(content.balance)}
          />

          <CardNumber
            icon="ti ti-report-money"
            upper="totel"
            name="count of calls"
            number={financialInt(content.count_of_call)}
            tooltip="all calls "
          />
        </div>
      </div>
      {tableSearch ? (
        <div className="pt-3">
          <TableHistoryApi arr={contentTableSearch} isLoading={loading} />
        </div>
      ) : (
        <>
           
          {history ? <TableHistoryApi arr={history} isLoading={loading} /> : ""}
          <Pagination
            activePage={pNumber}
            itemsCountPerPage={state?.per_page ? state?.per_page : 15}
            totalItemsCount={state?.total ? state?.total : 15}
            onChange={(pageNumber) => {
              if (pageNumber !== pNumber) {
                setLoading(true);
                setPNumber(pageNumber);
              }
            }}
            pageRangeDisplayed={4}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First"
            lastPageText="Last"
          />
        </>
      )}
    </div>
  );
};

export default BuHome;
