import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route   } from "react-router-dom";

 
import "./App.css";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import HistoryPay from "./pages/HistoryPay";
import BuHome from "./pages/business/BuHome";  
import NewTicket from "./pages/NewTicket";  
import Layout from './layouts/Layout'
import LayoutBusiness from './layouts/LayoutBusiness'


import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";

// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import Payout from "./pages/Payout";
import Tickets from "./pages/Tickets";
import Ticket from "./pages/Ticket";
import SendMoney from "./pages/SendMoney";
import HistorySendBalance from "./pages/HistorySendBalance";
import HistoryReciveBalance from "./pages/HistoryReciveBalance";
import Wallet from "./pages/Wallet";
import Cards from "./pages/Cards";
import Card from "./pages/Card";
import LayoutAuth from "./layouts/LayoutAuth";
import Document from "./pages/business/Document";
import HomeVist from "./pages/HomeVist";
import Verify from "./pages/Verify";
import Blocked from "./pages/Blocked";
import PendingAccount from "./pages/PendingAccount";
import Deposit from "./pages/Deposit";
import Purchases from "./pages/Purchases";
import Requests from "./pages/Requests";
import MyApps from "./pages/business/MyApps";
import ShowApp from "./pages/business/ShowApp";
 
const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
   <><Router history={history}>
      <div>
       <Switch>
        
       <Route exact path={"/"} component={HomeVist} />
       
       <Route path={['/Business', '/Document','/MyApps','/ShowApp/:id']}>
        <LayoutBusiness>
          <Route path="/Business" component={BuHome} /> 
          <Route path="/MyApps" component={MyApps} />
          <Route path="/ShowApp/:id" component={ShowApp} />
          <Route path="/Document" component={Document} />
          
        </LayoutBusiness>
      </Route>
      <Route path={[ '/login', '/register','/Verify','/Blocked','/PendingAccount']}>
      

            <LayoutAuth>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/Verify" component={Verify} />
            <Route exact path="/Blocked" component={Blocked} />
            <Route exact path="/PendingAccount" component={PendingAccount} />
            
            </LayoutAuth>
            </Route>
          <Layout >
            <Route exact path={"/home"} component={Home} />
           
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/payout" component={Payout} />
            <Route exact path="/historyPay" component={HistoryPay} />
            <Route exact path="/Tickets" component={Tickets} />
            <Route exact path="/NewTicket" component={NewTicket} />
            <Route exact path="/Ticket/:id" component={Ticket} />
            <Route exact path="/Card/:id" component={Card} />
            <Route exact path="/SendMoney" component={SendMoney} />
            <Route exact path="/Cards" component={Cards} />
            <Route exact path="/Wallet" component={Wallet} />
            <Route exact path="/HistorySendBalance" component={HistorySendBalance} />
            <Route exact path="/HistoryReciveBalance" component={HistoryReciveBalance} />
            <Route exact path="/Deposit" component={Deposit} />
            <Route exact path="/Purchases" component={Purchases} />
            <Route exact path="/Requests/:id" component={Requests} />
            
            </Layout>
            
          </Switch>
        
        
        {/* <AuthVerify logOut={logOut}/> */}
      </div>
    </Router>
    
  </> );
};

export default App;
