import React from "react";
import ReactLoading from "react-loading";

function LoadingSpinner(props){
    return (
      <ReactLoading type="bubbles" color={props.color ? props.color :"#6fd943" }
         height={props.height > 1 ? props.height :100} width={props.width ? props.width : 100} />
      )
  }
  
export default LoadingSpinner;