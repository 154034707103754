import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; 
const HomeVist = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light  border">
  <Link className="navbar-brand" to="/">
    POTIPAY
  </Link>
  <button className="navbar-toggler" type="button" onClick={toggleNav}>
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className={`navbar-collapse ${isNavVisible ? "d-block" : "d-none"}`}>
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link className="nav-link" to="/register">
          Register
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/login">
          Login
        </Link>
      </li>
    </ul>
  </div>
</nav>

      {/* Hero */}
      <div className="hero-container d-flex align-items-center" style={{
            backgroundImage: "url('assets/img/hero.png')", height:"100vh"
          }}>
        
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center text-white">
              <h1 className="display-4">Welcome to POTIPAY!</h1>
              <p className="lead">
                A place where you can find and buy the best game cards.
              </p>
              <hr className="my-4" />
              <p>Get started today and enjoy our great deals!</p>
              <Link
                className="btn btn-primary btn-lg"
                to="/register"
                role="button"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer fixed-bottom py-3 bg-light">
        <div className="container">
          <span className="text-muted">© 2023 POTIPAY</span>
        </div>
      </footer>
    </>
  );
};

export default HomeVist;