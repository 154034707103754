import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBoard } from "../actions/userAction";
import BarHistory from "../components/BarHistory"; 
import { logout } from "../actions/auth";
import { Redirect } from "react-router-dom";
import { checkSession } from "../functions/forms";

const Blocked = () => { 
    const { stateUser } = useSelector((state) => state.auth); 
    const dispatch = useDispatch();
   
    useEffect(() => {
      checkSession()
        getDataUser();
      }, []);
      const logOut = useCallback(() => {
        dispatch(logout());
      }, [dispatch]);
      
      const getDataUser = () => { 
        dispatch(getUserBoard())
          .then((response) => { 
           
          })
          .catch((e) => { 
           
           
          });
      
    };

    if(stateUser){
      return <Redirect to="/home" />;
    }
    
  return (
    <>
      <div className="dash-container">
        <div className="dash-content">
          <BarHistory namePage="Home" />
          <div className="row">
            <div className="col-sm-12">
              <div className="row"> 
              sorry you are blocked ! state is : {stateUser?"1":"0"}
                  <a className="dropdown-item" onClick={logOut}>
                    Logout
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blocked;