import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDepositHistory, getStatisticsUser } from "../actions/userAction";
import BarHistory from "../components/BarHistory";
import CardsWallet from "../components/CardsWallet"; 
import TableHistory from "../components/TableHistory";
import { checkSession } from "../functions/forms";
import { useLoding } from "../hooks/Loding";
import Pagination from "react-js-pagination"; 

const Wallet = () => { 
  const { loading, setLoading } = useLoding(); 
  const dispatch = useDispatch();
  const [history, setHistory] = useState([]);
  const [pNumber, setPNumber] = useState(1);
  const [state, setData] = useState([]);

  useEffect(() => {
    setPNumber(1); 
  }, []);

  useEffect(() => { 
    getDataAPi(pNumber);
    checkSession();
  }, [pNumber]);


  const getDataAPi = (pNumber) => {
    setLoading(true); 
    dispatch(getStatisticsUser(pNumber)).then(
      (response) => {  
        // setHistory((state) => { return ""} );
        setHistory((state) => {
          return response.data.history.data;
        });
        setData((state) => {
          return response.data.history;
        });
        setLoading(false); 
      },
      (error) => {
        setLoading(false);
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
 
      }
    );
  };


  return (
    <>
      <div className="dash-container">
        <div className="dash-content">
          <BarHistory namePage="Home" />
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <CardsWallet />                 
              </div>
              {history ? (
                <TableHistory arr={history} isLoading={loading} />
              ) : (
                ""
              )}
            </div>
            <Pagination
            activePage={pNumber}
            itemsCountPerPage={state?.per_page ? state?.per_page : 15}
            totalItemsCount={state?.total ? state?.total : 15}
            onChange={(pageNumber) => {
              if (pageNumber != pNumber) { 
                setLoading(true);
                setPNumber(pageNumber);
              }
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First"
            lastPageText="Last"
          />
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
