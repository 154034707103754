import React from "react";
import InputPhone from "../components/inputs/InputPhone";
import InputEmail from "../components/inputs/InputEmail";
import InputAccountNo from "../components/inputs/InputAccountNo";
import InputHaram from "../components/inputs/InputHaram";
import imageExists from "image-exists";

export const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };


export function checkSession(){
  const user = JSON.parse(localStorage.getItem("user"));
  const last_time =  localStorage.getItem('timer') ; 
  if(user?.data?.user?.email_verified_at === null && user?.data?.user?.type_account === "Business"){
    window.location.href = "/Verify";
  }
  if(user?.data?.user?.state_company === "unactive" && user?.data?.user?.type_account === "Business"){
    window.location.href = "/PendingAccount";
  }
  

 if(last_time < Date.now()){
  window.localStorage.clear(); //clear all localstorage
  window.location.href = "/Login";
 }
}


export function financialFloat(x) {  
  const number = Number.parseFloat(x).toFixed(2); 
  return number
 }
 
 export function financialInt(x) { 
   const number = Number.parseInt(x) ;
   return number
  }


  export function CheckImage(src) {

    var image = new Image();
    var url_image = "../companies/" + src;
    image.src = url_image;
    if (image.width == 0) {
       return ("https://dashboard.potipay.com/uploads/companies//" + src);
    } else {
      return (url_image)
     
    }
 
     
  }

  export function getInput(companyName, value, changeValue) {
  switch (companyName) {
    case "Paypal":
    case "PotiPay":
      return <InputEmail value={value} changeValue={changeValue} />;
    case "Payeer":
      return <InputAccountNo label="Account No." value={value} changeValue={changeValue} placeholder="P00000000"/>;

    case "Syriatel":
      return (
        <InputPhone
          value={value}
          changeValue={changeValue}
          placeholder="0992......"
        />
      );

    case "MTN":
      return (
        <InputPhone
          value={value}
          changeValue={changeValue}
          placeholder="0994......"
        />
      );

    case "VodafoneCash":
      return (
        <InputPhone
          value={value}
          changeValue={changeValue}
          placeholder="010......"
          maxlength="11"
        />
      );

    case "Haram":
      return <InputHaram value={value} changeValue={changeValue} />;

    default:
      return <InputAccountNo value={value} changeValue={changeValue} placeholder="account" label="account / email"/>;
      
  }
}
