import React, { useState } from "react"; 
import { useSelector } from "react-redux";
import CardName from "../components/CardName";
import ModalCopyEmail from "./ModalCopyEmail";
function CardsWallet(props) {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState("");
  const { user: currentUser } = useSelector((state) => state.auth);

  const handleClose = () => { 
        setShow(false);  
  }
  const handleCopy = () => {
     
    navigator.clipboard.writeText(currentUser?.data?.user?.email); 
    setCopied("Copied !");
    setInterval(
      ()=>{
        setShow(false);
      }
        ,4000  )
  }
 
  function popupCode(){
    setShow(true)
  }
  return (
    <>
     <ModalCopyEmail show={show} email={currentUser?.data?.user.email}  handleClose={handleClose}  handleCopy={handleCopy} copied={copied}/>
  
      <CardName
        icon="ti ti-arrow-bar-up"
         
        name="send"
        url={"/payout"}
        
      />
      {/* fa fa-arrow-down */}
      <CardName
        icon="ti ti-arrow-bar-to-down" 
        name="Reciver"
        onClick={popupCode}
        isNotUrl={true}
      />
 
      <CardName
        icon=" ti ti-shopping-cart-plus"
        color="#0f0"
        name=" buy Cards"
        url={"/Cards"}
      />
      {/* <CardName
        icon="ti ti-play-card"
        color="#0f0"
        name="Buy"
        url={"/Cards"}
      /> */}

      <CardName
        icon="ti ti-arrow-down-left"
        color="#0f0"
        name="deposit" 
        url={"/Deposit"}
        
      />
    </>
  );
}

export default CardsWallet;
