 
  
  import PayService from "../services/pay.service";
import userService from "../services/user.service";


  

export const payout = (paymentMethods, selectCard,amount,receiver,note) => (dispatch) => {
    return PayService.payout(paymentMethods, selectCard,amount,receiver,note) 
  };

  
  export const payoutHaram = (companyName, amount, receiver, note,phone,city ) => (dispatch) => {
    return PayService.payoutHaram(companyName, amount, receiver, note,phone,city ).then( 
      (response) => { 
        return response;
      },
     
    );
  };

  export const addDeposit = (companyId, amount, numberBill) => (dispatch) => {
    return userService.addDeposit(companyId, amount, numberBill) ;
  };