import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openTicketApi } from "../actions/userAction";
import Form from "react-validation/build/form";
import InputComponent from "../components/form/InputComponent";
import BarHistory from "../components/BarHistory";
import { useLoding } from "../hooks/Loding";
import { useTitle } from "../hooks/tickets/useTitle";
import { useMessage } from "../hooks/tickets/useMessage";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router";
import { checkSession } from "../functions/forms";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const NewTicket = () => {
  const form = useRef();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const routerHistory = useHistory();
  const { loading, setLoading } = useLoding();
  const { title, changeTitle } = useTitle();
  const { message, changeMessage } = useMessage();

  useEffect(() => {
    checkSession();
  },[])
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  const openTicket = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();

    if (title !== "" && message !== "") {
      dispatch(openTicketApi(title, message))
        .then(() => {
          toast.success("Successfully open new ticket!");
          setLoading(false);
          const timeout = setTimeout(() => {
            routerHistory.push("/Tickets");
          }, 3000);

          return () => clearTimeout(timeout);

          // props.history.push("/tikets");
          // window.location.reload();
        })
        .catch(() => {
          toast.error("sorry , an error occurred !");
          setLoading(false);
        });
    } else {
      toast.error("Error : title or message is empty  !");
      setLoading(false);
    }
  };
  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="Payout" />
        <div className="row">
          <div className="col-sm-12">
            <Form onSubmit={openTicket} ref={form}>
              <InputComponent
                label="Title"
                className="form-control"
                type="text"
                name="title"
                validations={[required]}
                value={title}
                onChange={changeTitle}
                placeholder="title"
              />

              <textarea
                type="text"
                name="message"
                className="form-control"
                value={message}
                onChange={changeMessage}
                placeholder="note"
              />

              <button
                className="btn-login btn btn-primary btn-block mt-2 login-do-btn"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Send</span>
              </button>
            </Form>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default NewTicket;
