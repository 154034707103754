import React from "react";

function SendReward(props) {
  const requestCode = `
    POST --url : https://dashboard.potipay.com/api/pay
        --header : 'Authorization: Bearer YOUR-API-KEY'
        --data : 
    {  
        "payment_method":"Payeer", 
        "amount" :"50", 
        "receiver" :  "P0000000", 
        "fee_from_amount" : 0  ,
         "test":true   ,  
         "note":"you can send any note to payeer or any pay method used"    
    }
`;

  const requestCardCode = `
POST --url : https://dashboard.potipay.com/api/pay
    --header : 'Authorization: Bearer YOUR-API-KEY'
    --data : 
{  
    "payment_method":"GooglePlay",  
    "card_id":"21" ,  // you can get id from path : pay_methods We explained it in the previous step
    "receiver" :  "test@test.com", 
    "fee_from_amount" : 0 // 1/0  0: The commission will be deducted from your balance and the full amount will be sent -- 
                              //      1 : Transfer commission will be deducted from the amount sent
    "test":true   , // just  for test  ,if live just delete this line
    "note":"you can send any note "    
}
`;

  const requestphoneCode = `
POST --url : https://dashboard.potipay.com/api/pay
    --header : 'Authorization: Bearer YOUR-API-KEY'
    --data : 
{  
    "payment_method":"VodafoneCash", // hash_name
    "receiver" :  "0102222222", 
    "fee_from_amount" : 0 // 1/0  0: The commission will be deducted from your balance and the full amount will be sent -- 
                              //      1 : Transfer commission will be deducted from the amount sent
    "test":true   , // just  for test  ,if live just delete this line
    "note":"you can send any note "    
}
`;

  const requestHaramCode = `
POST --url : https://dashboard.potipay.com/api/pay
    --header : 'Authorization: Bearer YOUR-API-KEY'
    --data : 
{  
    "payment_method":"Haram", // hash_name 
    "receiver" :  "+96399666666", 
    "name":"abd ali mhmad",
    "city":"manbij",
    "fee_from_amount" : 0 // 1/0  0: The commission will be deducted from your balance and the full amount will be sent -- 
                              //      1 : Transfer commission will be deducted from the amount sent
    "test":true   , // just  for test  ,if live just delete this line
    "note":"you can send any note "    
}
`;

  const responseCode = `
success : 
    [
        "success"=>1, // 1 or 0
        "state"=>"SUCCESS" , // maybe :  "SUCCESS" , "PENDING" ,"FAILED" , "RETURNED" ,  
        "message"=>"success", 
        "order_id"=> 510  
        
    ];

 error : 
    [
        "success"=>0,
        "message"=>"error"
    ];
`;

  return (
    <>
      <div className="card-body">
        <h5 className="card-title">Send the reward</h5>
        <p className="card-text">
          It is showtime. We have everything we need to place our first order.
        </p>
        <hr />
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Parameter</th>
              <th scope="col">Description</th>
              <th scope="col">Example</th> 
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">payment_method</th>
              <td>name of the payment method , you can get name from response (hash_name)</td>
              <td>PayPal,Payeer,googlePlay,Syriatel,Haram,Payonner ... etc</td> 
            </tr>
            <tr>
              <th scope="row">amount</th>
              <td>The amount you want to send</td>
              <td>any amount</td> 
            </tr>
            
            <tr>
              <th scope="row">card_id</th>
              <td>when select payment method and type_account is "card" You must send the ID number of the card you chose</td>
              <td>any number </td> 
            </tr>
            <tr>
              <th scope="row">receiver</th>
              <td>Email, account or name of the recipient and it varies according to the chosen payment method</td>
              <td>exam@exam.com , hani , P00000</td> 
            </tr>
            <tr>
              <th scope="row">fee_from_amount</th>
              <td>Determine the commission deduction from the recipient or from the sender , for example, if you specify the value of one and send an amount of $ 10 and the commission is 5%, it will reach the user’s account $ 9.5
                  If the value is zero, $10 will be sent to the user and approximately $10.6 will be deducted from your balance</td>
              <td>0 , 1(default 1 if not send the parameter)  </td> 
            </tr>
            <tr>
              <th scope="row">note</th>
              <td>you can write any note with order for example if you want send mony by use paypal and want to send note with order  you can write note here </td>
              <td>any note </td> 
            </tr>
            <tr>
              <th scope="row">test</th>
              <td>for test transferring yo can add this parameter and the value to select type response </td>
              <td> true : testing success , false :testing error </td> 
            </tr>
          </tbody>
        </table>
        <hr />
        <span className="card-text">
          <div className="alert alert-danger" role="alert">
            In the testing phase of the code,
            <br /> a parameter must be added with the name 'test' and its value
            'true' to get response success or value 'false' to get response
            error , to send live request just delete 'test'
          </div>
        </span>

        
        <span>
          Let’s send a $50 reward by use Payeer to P0000000 :
          <pre className="language-html">
            <code>
              <div>{requestCode}</div>
            </code>
          </pre>
        </span>
        <div className="alert alert-danger" role="alert">
          When sending an order we use hash_name instead of the payment method
          name we got from the previous step
        </div>

        <span>
          Let’s send a Gift card Google Play 5$ to test@test.com :
          <pre className="language-html">
            <code>
              <div>{requestCardCode}</div>
            </code>
          </pre>
        </span>

        <span>
          Let’s send a balance vodaphone to "0102222222" :
          <pre className="language-html">
            <code>
              <div>{requestphoneCode}</div>
            </code>
          </pre>
        </span>

        <span>
          Let’s send a balance to anyone in syria by "Haram transfer" (just the
          haram need Some additional information such as full name, phone number
          and city) :
          <pre className="language-html">
            <code>
              <div>{requestHaramCode}</div>
            </code>
          </pre>
        </span>
        <h6>API response</h6>
        <span>
          The API responds like this: :
          <pre className="language-html">
            <code>
              <div>{responseCode}</div>
            </code>
          </pre>
        </span>
        <h6 className="pt-3"> The Response </h6>
        <p className="pl-3">
          There are three types of payment methods <br />
          first type: It is companies transferring money such as PayPal or
          Bayeer etc
          <br />
          if the response is "succcess : 1 " <br />
          This means that the request has been sent to the transfer company.
          <br />
          "state" : you will get state's order in transfer company
          <br />
          in response there "order_id" This number should be stored in the
          database with the application or wherever it can be referenced (when
          we send you a postback we will send this number with its new status )
          <br />
          second type : it is charge cards such "google play" "pubg" etc <br />
          if the response is "succcess : 1 " <br />
          This means that The code will be sent to the email we received. <br />
          third type : <br />
          Various transfer methods such as mobilizing the balance of
          telecommunications companies or transferring through transfer
          companies if the response is "succcess : 1 " <br />
          This means that the request has arrived and has been registered and
          will be processed as soon as possible.
        </p>
        <div className="alert alert-primary" role="alert">
          Do not forget that there is a message with the response informing you
          of the status of the request !
        </div>
      </div>
    </>
  );
}

export default SendReward;
