import React from "react";

function Introduction(props) {
  return (
    <>
      <div className="card-body">
        <h5 className="card-title">Introduction</h5>
        <p className="card-text">
          The Tremendous API helps you send and manage rewards at scale
        </p>
        <hr />
        <h6>Welcome to the PotiPay API!</h6>
        <p className="card-text">
          We've designed the API so that nearly all
          functionality available via the web UI is available here.
        </p>
        <h6>Ready to build?</h6>
        <p>
          Follow our quickstart guide to make your first API request in just a
          few minutes.
        </p>

        <h6>API client libraries</h6>
        <p>Our docs are library agnostic and use HTTP primitives,</p>
      </div>
    </>
  );
}

export default Introduction;
