import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { myTickets } from "../actions/userAction";
import BarHistory from "../components/BarHistory";
import LoadingSpinner from "../components/LoadingSpinner";
import { checkSession } from "../functions/forms";
import { useLoding } from "../hooks/Loding"; 

const Tickets = () => {
  const [content, setContent] = useState([]);
  const { loading, setLoading } = useLoding(); 
  const dispatch = useDispatch();
  useEffect(() => {
    checkSession(); 
    setLoading(true)
    dispatch(myTickets()).then(
      (response) => {  
        setContent((state) => {
          return response.data.data.data;
        });
        setLoading(false);  
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setLoading(false)
        setContent(_content);
      }
    );
   
  }, []);
 
  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="Tickets">
          <Link
            to={"/NewTicket"}
            title="Create"
            className="btn btn-sm btn-primary"
          >
            <i className="ti ti-plus"></i>
          </Link>
        </BarHistory>
        <div className="row  card pb-5">
          <div className="col-sm-12">
            <table className="table table-bordered tickets">
              <thead>
                <tr>
                  <td>title</td>
                  <td>status</td>
                  <td>action</td>
                </tr>
              </thead>
              <tbody>
                
                {loading ? <LoadingSpinner /> :
                
                  content.map((t, k) => (
                    <tr key={k}>
                      <td>{t.title}</td>

                      <td>{t.status}</td>
                      <td>
                        <span>
                          <div className="action-btn bg-info ms-2">
                            <Link
                              to={"/Ticket/" + t.id}
                              className="mx-3 btn btn-sm align-items-center"
                              data-bs-toggle="tooltip"
                              title="View"
                            >
                              <i className="ti ti-eye text-white"></i>
                            </Link>
                          </div>
                        </span>
                      </td>
                    </tr>
                  )
                  )   
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
 