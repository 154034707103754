import React from "react";
import InputComponent from "../form/InputComponent";

function InputHaram(props) {
  return (
    <>
      <InputComponent
        label="Full Name"
        type="text"
        className="form-control heightInput"
        name="receiver"
        value={props.value}
        onChange={props.changeValue}
        placeholder="Full Name"
        required
      />
      
    </>
  );
}

export default InputHaram;
