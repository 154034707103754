import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";

function Navbar() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  useEffect(() => {}, []);
  return (
    <nav className="navbar navbar-expand-md ">
      <div className="row" style={{width:"100%"}}>
        
          <div className="col-9">
            <a className="navbar-brand" href="#">
              <img
                src="https://demo.rajodiya.com/erpgo-saas/storage/uploads/logo/logo-dark.png"
                alt=" Saas"
                 style={{width:"200px"}}
              />
            </a>
          </div>

          <div className="col-3  ">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fas fa-bars fa-1x text-dark" ></i>
            </button>
          </div>
          </div>

        <div
          className="collapse navbar-collapse"
          id="navbarTogglerDemo01"
          style={{ flexGrow: "0" }}
        >
          <ul className="navbar-nav align-items-center ms-auto mb-2 mb-lg-0">
            <li className="nav-item try_border">
              <Link to={"/Business"} className="nav-link">
                home
              </Link>
            </li>

            <li className="nav-item try_border">
              <Link to={"/home"} className="nav-link ">
                Member Area
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to={"/ApiKey"} className="nav-link">
                ApiKey
              </Link>
            </li> */}
            <li className="nav-item  try_border">
              <Link to={"/MyApps"} className="nav-link">
                MyApps
              </Link>
            </li>
            <li className="nav-item  try_border">
              <Link to={"/Document"} className="nav-link">
                Document
              </Link>
            </li>
          </ul>
        </div>
        
    </nav>
  );
}
export default Navbar;

{
  /* <Link to={"/home"} className="nav-link"> */
}
{
  /* Home
    </Link> */
}
