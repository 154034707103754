import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SideBar from "./SideBar";

function Layout({ children }) {
  return (
    <>
      <Navbar />
      {/* <SideBar /> */} 
      <main>{children}</main>
      <Footer />
    </>
  );
}
export default Layout;
