import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import BarHistory from "../components/BarHistory";
import TableRealCards from "../components/TableRealCards";
import { checkSession } from "../functions/forms";
import { useLoding } from "../hooks/Loding";

import UserService from "../services/user.service";

const Purchases = () => { 
  const [pNumber, setPNumber] = useState(1);
  const [history, setHistory] = useState([]);
  const { loading, setLoading } = useLoding();
  const [state, setData] = useState([]);
  useEffect(() => {
    setPNumber(1);
  }, []);

  useEffect(() => {
    getDataAPi(pNumber);
    checkSession();
  }, [pNumber]);

  const getDataAPi = (pNumber) => {
    UserService.getPurchases(pNumber).then(
      (response) => {
        setHistory((state) => {
          return response.data.RealCards.data;
        });
        setData((state) => {
          return response.data.RealCards;
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
 
      }
    );
  };

  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="History Payout" />

        <div className="container mt-3 p-2">
           
            {history ? (
              <TableRealCards arr={history} isLoading={loading} />
            ) : (
              ""
            )}
            <Pagination
              activePage={pNumber}
              itemsCountPerPage={state?.per_page ? state?.per_page : 15}
              totalItemsCount={state?.total ? state?.total : 15}
              onChange={(pageNumber) => {
                if (pageNumber != pNumber) {
                  setLoading(true);
                  setPNumber(pageNumber);
                }
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
         
        </div>
      </div>
    </div>
  );
};

export default Purchases;
