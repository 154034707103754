import React from "react"; 
import InputComponent from "../form/InputComponent";

function InputAccountNo(props) {
  return (
    <>
     <InputComponent
      label={props.label}
      type="text"
      className="form-control  heightInput"
      name="receiver" 
      value={props.value}
      onChange={props.changeValue}
      placeholder={props.placeholder}
      required
      />
    </>
  );
}

export default InputAccountNo;