import React, { useState, useEffect } from "react";
import BarHistory from "../components/BarHistory";
import UserService from "../services/user.service";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { useLoding } from "../hooks/Loding";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../components/LoadingSpinner";
import { checkSession, financialFloat } from "../functions/forms";
import { addDeposit } from "../actions/payAction";
import { useHistory } from "react-router-dom";

const Deposit = () => {
  // logding
  const { loading, setLoading } = useLoding();
  const [content, setContent] = useState("");
  const [step, setStep] = useState(1); 
  const [className] = useState("col-md-5  m-2 card");
  const [classNameActive] = useState("col-md-5 active1 m-2");
  const [companyId, setCompanyId] = useState(0);

  const [amount, setAmount] = useState(0);
  const [numberBill, setNumberBill] = useState("");
  const [fee, setFee] = useState(0);
  const [note, setNote] = useState("");

  //form
  const routerHistory = useHistory();
  const [instructions, setInstructions] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");

  const dispatch = useDispatch();
  const [calc, setCalc] = useState(0);
  useEffect(() => {
    checkSession();
    getData();
  }, []);
  const selectCard = (id) => {
    setCompanyId(content[id].id);
    setFee(content[id].fee);
    setInstructions(content[id].instructions);
    setEmail(content[id].email);
    setEmail1(content[id].email1);
    setNote(content[id].note);
    if (content[id].fee == 0) {
      setCalc(amount);
    } else {
      let fee1 = content[id].fee;
      const newFee = (amount * fee1) / 100;
      setCalc(parseFloat(amount) + parseFloat(newFee));
    }
  };
  //   t[1].id
  const OptionsComp = (k, t) => {
    return (
      <>
        <div
          key={k}
          className={companyId == t[1].id ? classNameActive : className}
        >
          <div className="card-body" onClick={() => selectCard(k)}>
            <div className="row ">
              <div className="col-4  ">
                <img
                  src={
                    "https://dashboard.potipay.com/uploads/companies/" +
                    t[1].image
                  }
                  className="rounded"
                  width="50"
                  height="50"
                  alt={t[1].name}
                />
              </div>
              <div className="row col-8">
                <div className="col-12  ">{t[1].name}</div>
                <div className="col-12 ">{t[1].fee} %</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  function calcFee(amount, fee) {
    const newFee = (amount * fee) / 100;
    setCalc(parseFloat(amount) + parseFloat(newFee));
  }

  function changeAmountToCalc(event) {
    setAmount(event.target.value);

    calcFee(event.target.value, fee);
  }

  const getData = () => {
    UserService.getCompanyDeposit().then(
      (response) => {  
        setContent(response.data.companies);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  };

  const nextStep = () => {
    setStep(step + 1);
  };
  const backStep = () => {
    setStep(step - 1);
  };

  const handlePayout = (e, props) => {
    e.preventDefault();
    // validate All input is requerd
    // set lodding
    if (companyId !== "" && amount !== "" && numberBill !== "") {
      setLoading(true);
      dispatch(addDeposit(companyId, amount, numberBill))
        .then((response) => { 
          if (response.data.success) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          setLoading(false);
          setTimeout(function () {
            routerHistory.push("/Wallet");
          }, 4000);

          // window.location.href = "/Wallet";
        })
        .catch(() => {
          toast.error("sorry , an error occurred try aging !");
          setLoading(false);
        });
    }
  };

  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="History Payout" />

        <div className="container mt-3 p-2">
          <div className="row">
            <div className={step == 1 ? "col-2 showStep" : "col-2 hideStep"}>
              {" "}
              choose{" "}
            </div>
            <div className={step == 1 ? "col-9 showStep" : "col-9 hideStep"}>
              <div className="row">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  Object.entries(content).map((t, k) => OptionsComp(k, t))
                )}
                <a
                  className="btn btn-default btn-rounded mb-4"
                  data-toggle="modal"
                  data-target="#modalSubscriptionForm"
                ></a>
                <div className="form-group">
                  <label for="Amount">Amount</label>
                  <input
                    type="number"
                    value={amount}
                    onChange={changeAmountToCalc}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Amount"
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    {note}
                  </small>
                </div>

                <div>
                  <br />
                  <div className="form-group">
                    <label for="Amount">fee : {fee} %</label>

                    <br />
                    <label for="Amount">
                      total amount : {financialFloat(calc)}
                    </label>
                  </div>
                </div>
                <div className="span2">
                  {" "}
                  <Button
                    className="px-3"
                    variant="primary"
                    onClick={nextStep}
                    disabled={
                      calc > 0 ? (companyId > 0 ? "" : "disabled") : "disabled"
                    }
                  >
                    next
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className={step == 2 ? "showStep" : "hideStep"}>
          <div className="row">
              <div className="col-2 font-weight-bold">
                instructions 
              </div>{" "}
              :
              <div className="col-9">
                {instructions} 
              </div>
            </div> 
            <hr />
            <div className="row">
              <div className="col-2 font-weight-bold">
                email  
              </div>{" "}
              :
              <div className="col-9">
                {email} 
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-2 font-weight-bold">
              Spare account 
              </div>{" "}
              :
              <div className="col-9">
               {email1} 
              </div>
            </div> 

              
            <br />
            <div className="row">
              <div className="col-2 font-weight-bold">
                Totla Amount 
              </div>{" "}
              :
              <div className="col-9">
                {financialFloat(calc)} 
              </div>
            </div>  
            <Button className="px-3 mt-4" variant="primary" onClick={nextStep}>
              next
            </Button>
          </div>
          <div className={step == 3 ? "showStep" : "hideStep"}>
          <div className="form-group">
            <label for="exampleInputEmail1">Number Bill</label>
            <input type="text" value={numberBill} onChange={(e) => setNumberBill(e.target.value)} className="form-control"  placeholder="number bill"/>
             </div>
           
            <Button className="px-3 mt-4" variant="primary" onClick={handlePayout}>
              done
            </Button>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Deposit;
