import React from "react"; 
import InputComponent from "../form/InputComponent";

function InputEmail(props) {
  return (
    <>
     <InputComponent
      label="email"
      type="email"
      className="form-control  heightInput"
      name="receiver" 
      value={props.value}
      onChange={props.changeValue}
      placeholder="email"
      required
      />
    </>
  );
}

export default InputEmail;
 