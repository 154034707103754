import React from "react";
import LoadingSpinner from "./LoadingSpinner";

function getStyle(state) {
  switch (state) {
    case "SUCCESS":
      return "p-2 px-3 rounded badge bg-success";

    case "PENDING":
      return "p-2 px-3 rounded badge bg-secondary";

    case "FAILED":
      return "p-2 px-3 rounded badge bg-danger";

    default:
      return "p-2 px-3 rounded badge bg-info";
  }
}
function TableHistoryPostback({ arr, isLoading }) {
  return (
    <>
      <div className="col-xxl-12">
        <div className="card" style={{ overflow: "hidden" }}>
          <div className="card-header">
            <h5 className="mt-1  ">last requests</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table
                className="table historyPostback"
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>order_id</th>
                    <th>url</th>
                    <th>state</th>
                    <th> note </th>
                    <th>Date </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <tbody>
                    {arr.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.order_id}</td>
                        <td> {item.url}</td>
                        <td>{item.state}</td>
                        <td>{item.note}</td>
                        <td>{item.date}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableHistoryPostback;
