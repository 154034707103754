import React, { useEffect, useState } from "react";
import BarHistory from "../components/BarHistory";
import CardNumber from "../components/CardNumber";
import TableHistory from "../components/TableHistory";
import Pagination from "react-js-pagination"; 
import { useDispatch, useSelector } from "react-redux";
import { getStatisticsUser } from "../actions/userAction";
import { useLoding } from "../hooks/Loding";
import { Redirect } from "react-router-dom";
import { checkSession ,financialFloat } from "../functions/forms";
import CardsWallet from "../components/CardsWallet";


 


const Home = () => {
  const [content, setContent] = useState([]);
  const [history, setHistory] = useState([]);
  const { loading, setLoading } = useLoding();
  const [state, setData] = useState([]);
  const [pNumber, setPNumber] = useState(1);
  const dispatch = useDispatch();
  const { stateUser } = useSelector((state) => state.auth); 
  
 
  useEffect(() => {
    setPNumber(1); 
  }, []);

  useEffect(() => { 
    getDataAPi(pNumber);
    checkSession();
  }, [pNumber]);

  const getDataAPi = (pNumber) => {
    dispatch(getStatisticsUser(pNumber)).then(
      (response) => {  
        setLoading(false); 
        setContent((state) => {
          return response.data.Statis;
        });
        // setHistory((state) => { return ""} );
        setHistory((state) => {
          return response.data.history.data;
        });
        setData((state) => {
          return response.data.history;
        });
      },
      (error) => {
        setLoading(false);
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  };

  if (stateUser === 0) {
    return <Redirect to="/Blocked" />;
  }

  return (
    <>
      <div className="dash-container">
        <div className="dash-content" >
          <BarHistory namePage="Home" />
          
          <div className="row"> 
          <CardsWallet />     
            <div className="row">
              <CardNumber
                icon="ti ti-report-money"
                upper="totel"
                name="Balance"
                number={financialFloat(content.balance)}
              />
              {/* <CardNumber
                icon="ti ti-report-money"
                upper="totel"
                name="success"
                number={financialFloat(content.sum_amount_success)}
              /> */}
              <CardNumber
                icon="ti ti-report-money"
                upper="totel"
                name="pending"
                number={financialFloat(content.sum_amount_pending)}
                tooltip = "pending in the transfer company"
              />
              {/* <CardNumber
                icon="ti ti-report-money"
                upper="totel"
                name="All Amounts"
                number={financialFloat(content.sum_all_amount_requests)}
              /> */}
            </div>

            {/* <CardsWallet /> */}
            <div className="pt-3">
              {history ? (
                <TableHistory arr={history} isLoading={loading} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div>
          <Pagination
            activePage={pNumber}
            itemsCountPerPage={state?.per_page ? state?.per_page : 15}
            totalItemsCount={state?.total ? state?.total : 15}
            onChange={(pageNumber) => {
              if (pageNumber != pNumber) { 
                setLoading(true);
                setPNumber(pageNumber);
              }
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First"
            lastPageText="Last"
          />
        </div>
      </div>
    </>
  );
};

export default Home;

//   <div className="container">
// balance : {content.balance}
// <br />
// count_of_call : {content.count_of_call}
// <br />
// sum_all_amount_requests : {content.sum_all_amount_requests}
// <br />
// sum_amount_failed : {content.sum_amount_failed}
// <br />
// sum_amount_success : {content.sum_amount_success}
// <br />
// sum_amount_pending : {content.sum_amount_pending}

// </div>
