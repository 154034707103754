import React, { useState, useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import { sendBalanceToApi } from "../actions/userAction";
import BarHistory from "../components/BarHistory";
import InputComponent from "../components/form/InputComponent";
import toast, { Toaster } from "react-hot-toast";
import { useNote } from "../hooks/forms/useNote";
import { useAmount } from "../hooks/forms/useAmount";
import { useReceiver } from "../hooks/forms/useRecever";
import CardsWallet from "../components/CardsWallet";
import { checkSession } from "../functions/forms";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const SendMoney = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { note, changeNote } = useNote();
  const { amount, changeAmount } = useAmount();
  const { receiver, changeReceiver } = useReceiver();
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => { 
    checkSession(); 
  }, []);
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  const handlePayout = (e) => {
    e.preventDefault();
    checkSession();
    if (amount > 0 && receiver !== "") {
      setLoading(true);
      dispatch(sendBalanceToApi(receiver, amount, note))
        .then(() => {
          toast.success("Successfully Send!");
          setLoading(false);
          // props.history.push("/profile");
          // window.location.reload();
        })
        .catch(() => {
          toast.error("sorry , an error occurred !");
          setLoading(false);
        });
    }
  };
  return (
    <div className="dash-container">
      <div className="dash-content">
        <BarHistory namePage="Send Balance PotiPay"></BarHistory>

        <div className="row">
        <CardsWallet />
        </div>
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <Form onSubmit={handlePayout} ref={form}>
              <InputComponent
                label="Amount"
                type="text"
                className="form-control"
                name="amount"
                validations={[required]}
                value={amount}
                onChange={changeAmount}
                placeholder="amount"
              />
              <InputComponent
                label="Receiver"
                className="form-control"
                type="text"
                name="receiver"
                validations={[required]}
                value={receiver}
                onChange={changeReceiver}
                placeholder="email"
              />
              <InputComponent
                label="Write Note If You Want"
                className="form-control"
                type="text"
                name="note"
                value={note}
                onChange={changeNote}
                placeholder="note"
              />

              <button
                className="btn-login btn btn-primary btn-block mt-2 login-do-btn"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Send</span>
              </button>
            </Form>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default SendMoney;
